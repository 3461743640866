.wrap {
  /* display: inline-block; */
  width: 100%;
}

.chkbxcolor {
  display: inline-block; /* Ensures the container only takes up as much space as its contents */
}

.checkcircle_icon {
  /* Add any additional styling here if necessary */
  border: none; /* Remove any border */
  outline: none; /* Remove outline */
}

.checkcircle_icon:hover {
  box-shadow: 0 0 8px 8px rgba(0, 134, 83, 0.04); /* Box shadow with 8px spread outside the icon */
  border-radius: 50%; /* Ensures the icon remains circular */
  background-color: rgba(0, 134, 83, 0.04);
}

a {
  color: #046386 !important;
  text-decoration: none;
}

.container_login {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 32px;
  display: flex;
  display: inline-flex;
}

.item {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.file-name {
  padding: 10px 8px !important;
}

.activate_btn {
  font-size: 13px !important;
  font-family: Roboto-medium !important;
  line-height: 22px !important;
}

.active-order .MuiTableCell-body.vertical-center {
  vertical-align: middle !important;
}

.vertical-center {
  vertical-align: center !important;
}

.Attach_doc {
  border: 1px solid var(--text-primary, rgba(0, 0, 0, 0.87)) !important;
  font-size: 15px;
  font-family: Roboto-medium !important;
  padding: 8px 22px !important;
}

.disable_doc {
  border: 1px solid var(--text-primary, rgba(0, 0, 0, 0.12)) !important;
  font-size: 15px;
  font-family: Roboto-medium !important;
  padding: 8px 22px !important;
}

.text {
  color: #046386;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 22px;
  letter-spacing: 0.46px;
  word-wrap: break-word;
}

.lognin-image {
  padding: 0px !important;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 0;
}

.loginlogo {
  margin-top: 0;
  margin-bottom: 0 !important;
}

.lognin-image img {
  margin-top: 0 !important;
}

.lognin-image a:focus {
  border: 1px solid var(--primary-outlinedBorder, rgba(4, 99, 134, 0.5)) !important;
  display: inline-block;
  border-radius: 4px;
}

.lognin-image img:focus {
  border: 1px solid var(--primary-outlinedBorder, rgba(4, 99, 134, 0.5)) !important;
}

.lognin-image img:focus-visible {
  border: 1px solid var(--primary-outlinedBorder, rgba(4, 99, 134, 0.5)) !important;
}

.divider {
  align-self: stretch !important;
  border: 1px rgba(0, 0, 0, 0.12) solid !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.sign-in-title {
  border-radius: 4px 4px 0px 0px;
  color: #046386;
  font-size: 24px !important;
  font-weight: 400;
  word-wrap: break-word;
  background: rgba(0, 0, 0, 0.04);
  padding: 8px 16px;
}
.form-border {
  padding: 16px;
  border: 1px rgba(0, 0, 0, 0.12) solid;
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.form-border .MuiAlert-message {
  font-family: Roboto-medium !important;
}

h1 {
  margin-bottom: 0px !important;
}

.weight-500 {
  font-weight: 500 !important;
  font-family: Roboto-medium !important;
}

.font14 {
  font-size: 14px !important;
}

.addr_label {
  font-weight: 500 !important;
  font-family: Roboto-medium !important;
}

.weight-600 {
  font-weight: 600;
}

/* .MuiContainer-root {
  width: 478px !important;
} */

.login-section {
  /* width: 608px; */
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  margin-top: 32px !important;
  margin: 0 auto;
  /* height: 100vh; */
}
/* .Privacy_info {
  display: flex;
  justify-content: space-around;
  width: 550px !important;
  margin-top: 100px;
}

.Privacy_info button {
  font-family: Roboto-medium !important;
  font-size: 13px;
} */

.Menu-item {
  padding: 6px 8px 6px 8px !important;
  margin-right: 16px !important;
  font-family: Roboto-medium !important;
}

.ptb-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.ptb-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.mlr-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mlr-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.p16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.plr-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* Common css */

.MuiDialogContent-root {
  padding: 0 16px !important;
}

.bcg-grey {
  background: var(--action-hover, rgba(0, 0, 0, 0.04)) !important;
}

@font-face {
  font-family: "Roboto-regular";
  src: url("../fonts/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto-Regular.woff") format("woff"),
    url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-bold";
  src: url("../fonts/Roboto-Bold.woff2") format("woff2"),
    url("../fonts/Roboto-Bold.woff") format("woff"),
    url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-medium";
  src: url("../fonts/Roboto-Medium.woff2") format("woff2"),
    url("../fonts/Roboto-Medium.woff") format("woff"),
    url("../fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.Popup-header {
  font-size: 20px !important;
  font-family: Roboto-medium !important;
  line-height: 32px !important;
  padding-bottom: 0 !important;
}

.headertitle {
  font-family: Roboto-regular !important;
  line-height: 32px;
  padding-top: 16px !important;
  padding-bottom: 8px !important;
}

/* .Multi-Order-form {
  padding: 48px 116px 58px 116px !important;
  height: calc(100vh - 176px);
  overflow-y: auto;
} */

.Multi-Order-form {
  padding: 48px 116px 58px 116px !important;
  height: auto; /* Allow height to adjust based on content */
  max-height: calc(
    100vh - 142px
  ); /* Set a maximum height to enable scrolling within the viewport */
  overflow-y: auto;
  position: relative; /* Ensure proper positioning within the parent container */
}

.Rate-screen {
  height: calc(100vh - 180px);
  overflow-y: auto;
}

.create-Order-form {
  height: calc(100vh - 142px);
  overflow-y: auto;
  padding: 48px 116px 70px 116px;
}

.h-113 {
  height: 113px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.rate:nth-child(1) {
  margin-right: -8px;
  padding-right: 10px;
  /* Adds 8px gap between the first and second Grid items */
}

.header-sub-title {
  font-size: 16px !important;
  font-family: Roboto-regular !important;
  line-height: 40px !important;
  /* padding-bottom: 16px; */
}

.search-full-width {
  height: 40px;
  font-size: 15px !important;
  font-family: Roboto-Medium !important;
}

.export_btn {
  font-size: 14px !important;
  font-family: Roboto-Medium !important;
  margin-top: 18px !important;
  margin-left: 16px !important;
}

.wh14 {
  width: 14px;
  height: 14px;
}

.ptb0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.w14h18 {
  width: 14px !important;
  height: 18px !important;
}

.Action_img_btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mt-32 {
  margin-top: 32px;
}
.pt-16 {
  padding-top: 16px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}
.mt-8 {
  margin-top: 8px !important;
}

.font48 {
  font-size: 48px !important;
}

.align-center {
  align-items: center;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.forgot-field .MuiFormControl-root {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-16 {
  margin-top: 16px !important;
}
.gap-16 {
  gap: 16px !important;
}

.lbl_head {
  font-size: 14px !important;
  font-family: Roboto-Medium !important;
  color: rgba(0, 0, 0, 0.6);
}

.Grid-height {
  min-height: 100px !important;
  height: 100px !important;
}

/* .MuiButtonBase-root{
  padding-top:7px!important;
  padding-bottom:3px!important;
} */

.Txtbx label {
  font-size: 14px !important;
  font-family: Roboto-regular !important;
}

/* .MuiTypography-root {
  font-family: Roboto-regular !important;
} */

/* .MuiInputBase-input {
  color: rgba(0, 0, 0, 0.6) !important;
  padding-top: 5px !important;
} */

/* .MuiButton-root {
  font-family: Roboto-regular !important;
} */
/* Common css end */

.form_info {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 0 !important;
  margin-bottom: 8px;
}

.signin_btn {
  font-size: 15px !important;
  font-family: Roboto-medium !important;
  padding: 8px 22px 8px 22px !important;
  line-height: 26px !important;
}

.form-border .MuiCheckbox-root {
  padding-right: 5px !important;
}

.form-border .MuiTypography-root {
  font-size: 14px;
}

.info_txt {
  font-size: 14px !important;
  font-family: Roboto-medium !important;
  padding-left: 10px !important;
}

.signup_detail {
  margin-top: 24px;
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.alignment-center {
  align-items: center;
}

/* Start privacy statement CSS */
.header {
  background-color: #fff !important;
  color: #046386 !important;
  border-bottom: 1px solid var(--primary-main, rgba(4, 99, 134, 1));
  box-shadow: none !important;
}

/* End privacy statement CSS */
.Forgot_txt {
  font-size: 15px !important;
  font-family: Roboto-medium !important;
  padding-left: 10px;
}

.MuiDrawer-paper {
  top: 73px !important;
  width: 25%;
}

.MuiModal-backdrop {
  background-color: transparent !important;
}

/* .signup_detail{
    margin-top: 25px;
    padding-top: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  } */

.info_lbl {
  font-size: 16px !important;
  font-family: Roboto-regular !important;
}

/* .signup_detail {
  margin-top: 25px;
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
} */

.Trace-section {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 20px;
  margin-top: 5px !important;
}

.container_login button {
  font-size: 13px;
  font-family: Roboto-medium !important;
}

.Track_btn {
  border-radius: 8px !important;
  font-size: 15px !important;
  font-family: Roboto-medium !important;
  padding: 7px 22px !important;
  line-height: 26px !important;
}

.WidthRightTrackinput {
  width: calc(100% - 260px);
}

.Trackinputfield {
  /* width: calc(100% - 470px); */
  width: calc(100% - 720px);
}

.Trackinput {
  width: calc(100% - 350px);
}

/* .mbneg5 {
  margin-bottom: -5px;
} */

.doc_action_btn {
  font-size: 14px;
  font-family: Roboto-medium !important;
  line-height: 24px;
  border: 0 !important;
  border-radius: 8px;
}

.pr-64 {
  padding-right: 64px;
}

.flex-end {
  justify-content: flex-end;
}

.Total_data {
  font-size: 14px !important;
  font-family: Roboto-bold !important;
}

.Total_field {
  background: var(--common-black-hover, rgba(0, 0, 0, 0.04)) !important;
}

.custom-dropdown-item.MuiSelect-select {
  background-color: rgba(8, 2, 18, 0.04) !important;
}

.custom-dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
  cursor: pointer;
}

.custom-dropdown-item.Mui-focused {
  background-color: rgba(0, 0, 0, 0.12) !important;
}


.Main_head_lbl {
  padding-top: 8px;
  padding-bottom: 0px;
  display: block;
}

.doc_btn {
  font-size: 15px !important;
  font-family: Roboto-medium !important;
  line-height: 26px !important;
}

.chk_bx_batchprint {
  margin-left: 8px !important;
  margin-right: 8px !important;
  padding: 0 !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.width-name {
  width: calc(100% - 300px);
}
@media only screen and (min-height: 440px) {
  .Signin_btn {
    position: absolute !important;
    bottom: 0 !important;
  }
}

@media only screen and (min-height: 1110px) {
  .privacy-footer {
    position: absolute !important;
    bottom: 0;
  }
}

@media only screen and (min-height: 920px) {
  .Privacy_info {
    position: absolute;
    bottom: 0;
  }

  .privacy-footer {
    position: static;
  }

  .signup_info {
    position: static !important;
  }
}

@media only screen and (max-height: 600px) {
  .no_data_section_field {
    height: 80% !important;
    overflow-y: auto;
  }
}

/* Start Active Table CSS   */

/* table thead{
  position:sticky;
  top:0;
  z-index:10000;
  background-color:white;
  border-bottom:1px solid rgba(0,0,0,0.12);
} */

.ActiveOrder {
  margin-top: 0px;
  margin-bottom: 0 !important;
}

.active-status {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #e1ecf0 !important;
  border-radius: 100px !important;
  border: 1px solid rgba(4, 99, 134, 0.5);
  display: inline-flex;
  align-items: center;
  gap: 2px;
}

.status {
  color: #035b7e;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  line-height: 1.43 !important;
  letter-spacing: 0.17px !important;
  word-wrap: break-word;
}

.track-order .MuiTableCell-head,
.code-form .MuiTableCell-head {
  vertical-align: top !important;
  padding: 8px !important;
  font-size: 14px;
  font-family: Roboto-medium !important;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
}

.active-order .MuiTableCell-head,
.track-order .MuiTableCell-head,
.code-form .MuiTableCell-head {
  vertical-align: top !important;
  padding: 8px !important;
  font-size: 14px;
  font-family: Roboto-medium !important;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
}

/* .address-book .MuiTableCell-body {
  background-color: rgba(0, 0, 0, 0.04);
} */

.active-order .MuiTableCell-body,
.code-form .MuiTableCell-body {
  vertical-align: top !important;
  padding: 8px !important;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto-regular;
}

.Rateestimate-total {
  background: var(--text-focus, rgba(0, 0, 0, 0.12)) !important;
}

.Rate-screen .MuiTableCell-body {
  padding: 5.5px 8px !important;
}

.Piecesgrid {
  padding-left: 10px !important;
  padding-right: 0 !important;
}

.Piecesgrid .MuiTableCell-body {
  vertical-align: middle !important;
}

.grid_chkbx {
  padding: 0 !important;
}

.data-no-found {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  border-bottom: none !important;
}

.commodity-header thead tr th {
  padding: 0 3px;
  height: 40px;
}

.commodity-table tr td {
  padding: 0 3px;
  height: 40px;
}

.mr-16 {
  margin-right: 16px;
}

.mr-8 {
  margin-right: 8px !important;
}

.width-20 {
  width: 20%;
}

.width-80 {
  width: 80%;
}

/* .order-form{
  margin-right:-10px!important;
} */

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pagination p {
  margin: 0px !important;
}

.Dialog-content {
  padding-bottom: 0 !important;
}

.wrap-txt {
  white-space: wrap !important;
}

/* Grid height resizing code */

.delivery-date {
  font-size: 14px !important;
  font-family: Roboto-regular !important;
}

.pod_datetime {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 20px;
}

.table-container {
  overflow-y: auto; /* Enable vertical scrolling */
  height: calc(100vh - 250px); /* Adjust the height accordingly */
}

.table-container-1 {
  overflow-y: auto; /* Enable vertical scrolling */
  height: calc(100vh - 366px); /* Adjust the height accordingly */
}

.Recent-delivery-grid {
  overflow-y: auto; /* Enable vertical scrolling */
  height: calc(100vh - 265px) !important; /* Adjust the height accordingly */
}

.invoice-grid {
  overflow-y: auto; /* Enable vertical scrolling */
  height: calc(100vh - 250px); /* Adjust the height accordingly */
}

.Batchprint-grid {
  overflow-y: auto; /* Enable vertical scrolling */
  height: calc(100vh - 300px); /* Adjust the height accordingly */
}

/* Grid height resizing code end*/
.pagination .MuiTableCell-root {
  padding: 0 0px !important;
}

.Auto-height {
  height: auto !important;
}

.Refergroup-found {
  height: calc(100vh - 370px);
  position: relative;
}

.whitebcg {
  background-color: transparent !important;
}

.Nodata_found {
  height: calc(100vh - 295px);
  position: relative;
}

.blue-btn {
  font-size: 15px !important;
  font-family: Roboto-medium !important;
}

.blue-btn .MuiButton-icon img {
  margin-bottom: 1px;
}

.Nodata_found .MuiTableCell-root {
  border-bottom: none !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MuiTablePagination-input {
  padding: 4px 20px 4px 0 !important;
  border: 1px solid
    var(--components-input-outlined-enabledBorder, rgba(0, 0, 0, 0.23));
  background-color: transparent !important;
  border-radius: 4px;
  font-size: 16px !important;
  width: 80px;
  height: 40px;
}

.MuiTablePagination-input svg {
  margin-right: 5px;
}

.MuiTablePagination-input .MuiTablePagination-select {
  background-color: transparent !important;
  padding-right: 16px !important;
  padding-left: 0;
}

.Footer-lbl {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
}

.footer-paginator {
  display: flex !important;
  justify-content: center !important;
}

.footer-paginator .MuiTypography-root {
  font-size: 10px;
  font-family: Roboto-medium !important;
  line-height: 14px;
}
/* 
.dropdown-combo {
  margin-top: 15px;
} */

.privacylbl {
  color: #fff !important;
  font-size: 13px !important;
  margin-left: 20px !important;
  text-transform: uppercase;
}

.status-info {
  background-color: var(--blue-50, rgba(225, 236, 240, 1));
  border: 1px solid var(--primary-outlinedBorder, rgba(4, 99, 134, 0.5));
  color: var(--primary-dark, rgba(3, 91, 126, 1));
  font-style: normal;
  padding: 2px 10px;
  border-radius: 100px;
}

.status_lbl {
  font-size: 14px !important;
  font-family: Roboto-regular !important;
  color: rgba(0, 0, 0, 0.87);
}

.ActiveOrder .MuiTableFooter-root {
  display: flex;
  justify-content: end;
  padding-top: 16px;
  padding-bottom: 16px;
}

.ActiveOrder .MuiTableCell-footer {
  border-bottom: none !important;
}
/* End Active Table CSS   */
/* 
.MuiButtonBase-root:hover{
  background-color: rgba(4, 99, 134, 0.04)!important;
} */

.Header-bar {
  height: 65px;
}

.Top-nav-btn {
  width: 40px;
}
.sidebar {
  padding-bottom: 80px;
}

/* .css-1160xiw-MuiPaper-root-MuiDrawer-paper{
    top:64px!important
  } */

/* .sidebar ul li{
    font-size:14px;
    font-weight:400;
  } */

.sidebar ul li .MuiListItemButton-root {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 25px;
}

.sidebar
  ul
  li
  .MuiListItemButton-root
  .MuiListItemText-root
  .MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
}

.sidebar ul li a {
  font-family: Roboto-regular !important;
  color: rgba(0, 0, 0, 0.87) !important;
  padding-left: 25px !important;
  text-decoration: none !important;
}

.sidebar ul li a .MuiListItemText-root .MuiTypography-root {
  font-size: 14px !important;
}
.sidebar ul li:first-child {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}

.Registerbtn {
  border: 1px solid var(--primary-outlinedBorder, #04638680) !important;
  width: 100px;
  height: 36px;
  font-family: Roboto-medium !important;
}

.MuiDrawer-root {
  position: unset !important;
}

/* Active Order CSS */

/* .Main-container {
  padding: 0 0 34px !important;
} */

.ActiveOrder {
  box-shadow: none !important;
}
/* 
.Batch-grid-footer {
  height: 106px;
} */

.Main-Header {
  font-size: 24px !important;
  font-family: Roboto-regular !important;
}

.active-order .MuiTablePagination-root,
.track-order .MuiTablePagination-root,
.code-form .MuiTablePagination-root {
  border-bottom: none !important;
}

.MuiTablePagination-menuItem:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.border-top {
  border-top: 1px solid rgba(224, 224, 224, 1);
}

.textCenter {
  text-align: center !important;
}

.new_btn {
  font-size: 13px !important;
  font-family: Roboto-medium !important;
  height: 30px;
  padding: 4px 10px !important;
  margin: 8px !important;
}

.addr_head {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}

.No-data-section {
  width: 100%;
  height: 100%;
}

.no_data_section_field {
  width: 608px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 8px 16px 16px;
}

.no-data-header {
  font-size: 24px;
  font-family: Roboto-regular;
  font-weight: 400;
  float: left;
  width: 100%;
  text-align: left;
  line-height: 32px;
}

.no-data-para {
  float: left;
  width: 100%;
  text-align: left;
  margin-top: 6px;
  font-size: 16px;
  font-family: Roboto-regular;
}

.next-step-btn {
  float: left;
  width: 100%;
  margin-bottom: 0px !important;
}

.btn-50 {
  width: 50%;
}

.mr-16 {
  margin-right: 16px !important;
}

.MuiTablePagination-toolbar {
  min-height: 40px !important;
}

.width700 {
  min-width: 700px !important;
}

.width94 {
  min-width: 94px !important;
}

/* Long Order Form css */

.add-edit_btn {
  font-size: 15px !important;
  font-family: Roboto-medium !important;
  padding: 8px 22px !important;
}

.delete_btn {
  color: #f93549 !important;
  border: 1px solid rgba(249, 53, 73, 0.5) !important;
}

.delete_btn:hover {
  background-color: rgba(253, 233, 235, 0.5) !important;
}

.h-100 {
  height: 100px;
}

.table-fixed {
  display: table !important;
  table-layout: fixed !important;
  width: 100% !important;
  padding: 0px;
}

.Refer_lbl {
  margin-bottom: 8px !important;
  font-size: 14px !important;
  font-family: Roboto-medium !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.my-settings {
  height: calc(100vh - 142px);
  overflow-y: auto;
}

.pl-0 {
  padding-left: 0 !important;
}

.p8 {
  padding: 8px !important;
}

.ptb-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.Search-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

/* .search-order-panel .MuiTableFooter-root {
  margin-bottom: 35px !important;
} */

.dropdown-grid,
.dropdown-grid tbody,
.dropdown-grid thead,
.Table-head thead tr {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  opacity: 1 !important;
  min-height: auto !important;
}

.piece-info {
  font-size: 14px !important;
  font-family: Roboto-Medium !important;
  line-height: 21px !important;
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
}

.thead-cell {
  color: rgba(0, 0, 0, 0.87) !important;
}

.table-fixed th,
.table-fixed td {
  padding: 0;
  height: 36px;
}

.width104 {
  width: 104px;
}

.width110 {
  width: 110px;
}

.width300 {
  width: 300px;
}

.width200 {
  width: 200px;
}

.width230 {
  width: 230px;
}

.Info-wrap {
  overflow-wrap: break-word;
  white-space: normal;
}

.width-750 {
  width: 750px;
}

.width180 {
  width: 180px;
}

.width230 {
  width: 230px;
}

.width160 {
  width: 160px;
}

.width600 {
  width: 600px;
}

.width144 {
  width: 144px;
}

.width94p {
  width: 94px;
}

.test {
  min-width: 200px !important;
  width: 200px !important;
}
/* 
.main-header{
  line-height:56px;
} */

.Sub-header {
  font-size: 20px !important;
  font-family: Roboto-medium !important;
  padding-bottom: 16px !important;
  line-height: 32px !important;
}

.addresslookup-grid {
  /* min-height: 200px;
  max-height:200px; */
  overflow-y: auto !important;
  height: calc(100% - 200px);
}

.pickup_section {
  margin-top: 24px !important;
  margin-left: 8px !important;
  font-size: 24px !important;
}

.Search_btn {
  padding: 6px 16px 6px 16px !important;
  font-size: 14px !important;
  font-family: Roboto-medium !important;
  border-radius: 8px !important;
  height: 36px;
  margin-top: 2px !important;
  margin-left: 16px !important;
}

.code-form_section {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
  margin-bottom: 16px;
  /* padding-top:16px; */
}

.pt-24 {
  padding-top: 24px;
}

.pb-0 {
  padding-bottom: 0;
}

/* .grid-border{
  border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12))
} */

.sub-infolbl {
  font-size: 14px !important;
  font-family: Roboto-medium !important;
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
}

.sub-header-lbl {
  font-size: 14px !important;
  font-family: Roboto-medium !important;
  color: rgba(0, 0, 0, 0.87);
}

.txt_info_lbl {
  font-size: 14px !important;
  font-family: Roboto-regular !important;
  color: rgba(0, 0, 0, 0.87);
}

.textcolor {
  color: rgba(0, 0, 0, 0.6);
}

.mt-16 {
  margin-top: 16px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pb-32 {
  padding-bottom: 32px;
}

.pb-40 {
  padding-bottom: 40px;
}

.mt-40 {
  margin-top: 40px !important;
}

.align-flex-start {
  align-items: flex-start;
}

.Margin0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mb-50 {
  margin-bottom: 50px;
}

.pull-right {
  float: right;
}

.Shipper_box .Divider {
  border-bottom: none;
}

.Add-icon {
  width: 20px !important;
  height: 20px !important;
}

.detail-info-section {
  padding: 16px;
}

.Divider {
  border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
}

.Divider-top {
  border-top: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
}

.properlbl {
  font-size: 14px;
  font-family: Roboto-regular;
}

.page-footer .MuiTableFooter-root .pagination .MuiTableCell-footer {
  border-bottom: none !important;
}

/* Long Order Form css end */

/* Multi form Order */

/* Multi Form Order End */

.main-header {
  line-height: 56px !important;
}

.pickup_section {
  margin-top: 16px !important;
  margin-left: 8px !important;
  font-size: 24px !important;
}

.input-label {
  font-family: Roboto-regular;
}

.gap-8 {
  gap: 8px !important;
}

.gap-12 {
  gap: 12px !important;
}

.mb-50 {
  margin-bottom: 50px;
}

.Add-icon {
  width: 40px;
  height: 40px;
}

.width-10 {
  width: 10%;
}

.width-90 {
  width: 90%;
}

.package-header {
  font-size: 16px !important;
  font-family: Roboto-regular !important;
  line-height: 175% !important;
  margin-bottom: 8px !important;
}

.MuiButton {
  font-size: 15px !important;
  font-family: Roboto-Medium !important;
  border-radius: 8px !important;
}

.Action-btn {
  width: 82px;
  height: 42px;
}

.padding0 {
  padding: 0 !important;
}

.greybcg {
  background: var(--text-hover, rgba(0, 0, 0, 0.04));
}

.borderbottom0 {
  border-bottom: 0 !important;
}

.verticaltop {
  vertical-align: top !important;
}

.ptb10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.plr0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.plr8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.h-42 {
  height: 42px;
}

.width50 {
  width: 50%;
}

.my-settings {
  padding: 16px 128px;
}

.mb-32 {
  margin-bottom: 32px;
}

.sub_header {
  font-size: 16px;
  font-family: Roboto-regular;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 16px;
}

.global-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Optional: Adds a light overlay */
  z-index: 9999; /* Ensure it is on top of other elements */
}

.hide-search-default {
  display: none !important;
}
.width-100 {
  width: 100%;
}

.width33 {
  width: 33.33%;
}

.active-order,
.track-order {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.flex-column-view {
  flex-direction: column;
}

.track-order .MuiTableCell-body {
  padding: 9.5px 8px !important;
  vertical-align: center !important;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto-regular;
}
.track-order .MuiTableCell-head {
  padding: 7.5px 8px !important;
  font-size: 14px !important;
  font-family: Roboto-medium !important;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
}

.Track-route-grid {
  box-shadow: none !important;
}

/* Batch Print - MNX & UPS Labels css */

.Batch-print-grid {
  height: calc(100vh - 73px);
  overflow-y: auto;
}

.Batch-print-section {
  height: calc(100vh - 142px);
  overflow-y: auto;
}

.Del_company {
  font-size: 14px !important;
  font-family: Roboto-medium !important;
  line-height: 20px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 500 !important;
  display: block;
}

.del_company_detail {
  font-size: 14px !important;
  font-family: Roboto-regular !important;
  font-style: normal;
  line-height: 20px;
}

.info-text {
  font-size: 12px !important;
  font-family: Roboto-regular !important;
  color: rgba(0, 0, 0, 0.6) !important;
  line-height: 20px !important;
  letter-spacing: 0.4px !important;
  width: 50%;
}

.side-by-side-header {
  width: 50%;
}

.Header-main {
  display: flex;
  width: 100%;
}

.Search_small_btn {
  font-family: Roboto-Medium !important;
  font-size: 15px !important;
  line-height: 26px !important;
  letter-spacing: 0.46px !important;
  text-transform: uppercase !important;
  padding: 8px 22px !important;
}

.Search-lbl {
  font-size: 16px;
  font-family: Roboto-regular;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.15px;
}

.Sub_info_section {
  margin-top: 8px;
  margin-bottom: 16px;
}

.Sub_info_text {
  font-size: 16px !important;
  font-family: Roboto-regular !important;
  line-height: 24px !important;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.15px !important;
}

.info_text {
  font-size: 14px !important;
  font-family: Roboto-regular !important;
  line-height: 24px !important;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.15px !important;
}

.row-highlight {
  background: var(--primary-selected, rgba(4, 99, 134, 0.08)) !important;
}

/* Batch Print - MNX & UPS Labels css end */

/* Track order css */
.track_flight_btn {
  width: 150px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  border-radius: 8px !important;
  border: 1px solid !important;
}
.Track_btn_section {
  display: flex;
}

.Track-section {
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 16px;
}

.Assignment_icon {
  width: 22px !important;
  margin-right: 12px;
}

.Track_info_lbl {
  background-color: #e0f2f5;
  padding: 6px;
  border-radius: 4px;
  padding: 6px 16px !important;
  margin-bottom: 8px;
}

/* Track Order */
.trackorder-success-toast {
  margin-bottom: 8px;
}
/* AttachDocsDialog.css */

.file-upload-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  position: relative;
  flex-direction: row;
}

.file-upload-container-column {
  flex-direction: column;
  align-items: flex-start;
}

.text-field-trackOrder {
  max-width: calc(100% - 235px);
  flex-grow: 1;
}

.button-container {
  display: flex;
  gap: 8px;
  position: absolute;
  right: 0;
  top: 0;
  width: 225px; /* Fixed width for button container */
  height: 40px;
}

.button-container .MuiButton-root {
  width: 100%;
  border-radius: 8px;
}

.Status_label {
  font-size: 14px !important;
  font-family: Roboto-Regular !important;
  letter-spacing: 0.17px !important;
  line-height: 20px !important;
  padding-top: 2px;
}

.condition-content {
  height: 350px;
  overflow-y: auto;
}

.Order_btn {
  padding: 6px 16px !important;
  height: 40px;
  border-radius: 8px;
}

.border-grey {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.Border-line {
  border: 1px solid #d9d9d9;
  height: 32px;
  width: 1px;
  margin-top: 4px;
  margin-right: 12px;
  margin-left: 12px;
}

.Attach_btn {
  color: #100249;
  border: 1px solid rgba(16, 2, 73, 0.5) !important;
  font-size: 14px !important;
  font-family: Roboto-medium !important;
}

.Map-view {
  border: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
  border-radius: 4px;
}

.Route-map {
  height: 255px !important;
}

.track_lbl {
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-family: Roboto-regular !important;
  line-height: 19px !important;
  padding-bottom: 4px !important;
}

.comp_name {
  font-size: 16px !important;
  font-family: Roboto-medium !important;
  line-height: 24px !important;
}

.grey-color {
  color: rgba(0, 0, 0, 0.6);
}

.List_btn {
  justify-content: flex-end;
  display: flex;
  width: 100%;
}

.List_btn Button {
  width: 222px;
}

.other_dtl {
  font-size: 16px !important;
  font-family: Roboto-regular !important;
  line-height: 24px !important;
}

.Phone_num {
  font-size: 16px;
  font-family: Roboto-regular;
  color: #046386;
  padding-left: 4px;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.Route_selection {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.sub-track-header {
  font-size: 20px !important;
  font-family: Roboto-medium !important;
  line-height: 32px !important;
  letter-spacing: 0.15px !important;
}

.Track-screen {
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
}

.maxwidth100 {
  max-width: 100% !important;
}

.mat-table-call-order1 {
  height: calc(100vh - 258px) !important;
  overflow-y: auto;
}

.mat-table-call-order {
  height: calc(100vh - 325px) !important;
  overflow-y: auto;
}

.will-call-order {
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: Roboto-regular !important;
  margin-bottom: 8px !important;
  margin-top: 24px !important;
}

.edit-activeorder {
  height: calc(100vh - 160px);
  overflow-y: auto;
}

.addr_lbl1 {
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
  font-family: Roboto-regular !important;
  font-size: 12px !important;
  font-style: normal;
  line-height: 166%; /* 19.92px */
  letter-spacing: 0.4px;
}

.choose_doc_input {
  width: 384px !important;
}

.address-grid {
  height: calc(100vh - 355px);
  overflow-y: auto !important;
}

.nodata-section .No_data .no_data_section_field .no-data-para {
  margin-bottom: 0 !important;
}

.Nodata_found .MuiTableCell-root {
  position: static !important;
  transform: none !important;
}

.return-list-btn {
  font-size: 15px !important;
  font-family: Roboto-medium !important;
  letter-spacing: 0.46px !important;
}

/* Privacy statement */

.privacy-statement {
  padding: 16px 24px !important;
  display: table;
  width: 100%;
}

.Main-header-title {
  font-size: 24px !important;
  font-family: Roboto-regular !important;
  margin-bottom: 16px !important;
  line-height: 32px !important;
}

.Privacy_page_details {
  box-shadow: none !important;
  width: 620px;
  display: table;
  margin: 0 auto;
}

.Privacy_page_info {
  font-size: 16px !important;
  font-family: Roboto-regular !important;
  line-height: 24px;
}

.hidden {
  display: none;
}

.Grid_button {
  display: flex;
  margin-top: 16px;
}

.Grid_button .MuiButton-root {
  width: auto !important;
}

.Rate_info {
  margin-bottom: 0 !important;
  padding-left: 16px;
}

.Rate_info li {
  color: rgba(0, 0, 0, 0.6);
}

.rate-popup .MuiDialog-container .MuiPaper-root {
  margin: 16px !important;
}

.margin-16 {
  margin: 16px !important;
}

/* Import Order */
.import-Order {
  padding-left: 24px !important;
}

.importFile {
  padding: 8px 12px;
}

.import_left-section {
  width: 45%;
}

.divfile {
  width: 100%;
  display: flex;
}

.browseBtn {
  font-size: 15px !important;
  font-family: Roboto-medium !important;
  padding: 8px 22px !important;
  line-height: 26px !important;
  max-height: 40px;
  border-radius: 8px !important;
}

.import-popup {
  letter-spacing: 0.4px !important;
  line-height: 20px !important ;
}

/* 
.importBtn {
 
  margin-left: 8px !important;
  height: 38px !important;
} */

.importFile-field {
  /* width: calc(100% - 230px) !important; */
  margin-right: 8px !important; /* Added margin to separate from the button */
}

.templateBtn {
  font-size: 13px !important;
  font-family: Roboto-medium !important;
  line-height: 22px !important;
  border-radius: 8px !important;
}

.no_data_order_field {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 8px 16px 16px;
  margin-left: 10px;
  margin-right: 10px;
}

.import_no_data_field {
  width: 98%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 8px 16px 16px;
}

.btn-100 {
  width: 100%;
}

.cancelDeleteIcon {
  color: #000000;
  font-size: 20px !important;
}

.dialogtitle {
  padding-right: 16px !important;
  padding-left: 16px !important;
  font-size: 20px !important;
  line-height: 32px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto-medium !important;
}

.dialogcontent {
  font-size: 12px;
}

.scrollable-list {
  max-height: 180px; /* Adjust the height as needed */
  overflow-y: auto;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.Field_header {
  font-size: 14px !important;
  font-family: Roboto-medium !important;
  color: #000;
  letter-spacing: 0.1px !important;
}

.reset_btn {
  font-size: 14px !important;
  font-family: Roboto-medium !important;
  border-radius: 8px !important;
}

.Import_list_btn {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.Rate-total-amount {
  font-size: 14px;
  font-family: Roboto-bold !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.Import_list {
  font-size: 14px;
}

.Import_data_Section {
  flex-direction: row;
}

.border8 {
  border-radius: 8px !important;
}

.Desctable {
  width: 100%;
}

.value-th {
  width: 100%;
}

.refer-txt {
  font-size: 14px;
  font-family: Roboto-medium !important;
}

.active-order-file {
  padding: 8px;
}

@media only screen and (max-width: 905px) {
  .table-container-1 {
    height: calc(100vh - 424px);
  }
  .ground-route {
    margin-right: 0 !important;
  }

  .active_search_btn {
    width: 160px !important;
  }

  .reset_btn_filter {
    width: 195px !important;
  }

  .reference-no-data-field {
    width: 80%;
  }

  .import_no_data_field_reference {
    width: calc(100% - 140px) !important;
    right: 30px;
    transform: translateY(-50%) !important;
    left: 130px !important;
  }
  .Desctable {
    width: 342px;
  }

  .table-section {
    height: calc(100vh - 200px) !important;
  }

  .table-container1 {
    height: auto !important;
  }

  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper {
    overflow-x: auto !important;
  }
  .export-btn {
    width: 100% !important;
    /* margin-bottom: 8px !important;
    color: black !important; */
  }
  .dropdown-grid tbody {
    overflow-x: auto;
  }

  .invoice-grid {
    height: calc(100vh - 75px); /* Adjust the height accordingly */
  }

  .Batchprint-grid {
    height: calc(100vh - 100px);
  }
  .Grid_button {
    width: 100%;
    justify-content: end;
    flex-direction: row-reverse !important;
    margin-top: 0;
  }
  .my-settings {
    padding: 16px;
  }
  .address-grid {
    height: calc(100vh - 225px);
  }

  .active-order,
  .track-order {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .Privacy_page_details {
    width: 469px;
  }

  .Flex-row {
    flex-direction: row;
  }

  .Flex-50 {
    width: 50%;
  }

  .pickup_section {
    margin-top: 24px !important;
  }

  .No-lbl-found {
    height: calc(100vh - 365px) !important;
  }

  .Nodata-height {
    height: calc(100vh - 390px) !important;
  }

  .Nodata_found {
    height: calc(100vh - 320px);
  }

  .MuiButtonBase-root-MuiButton-root:hover {
    background-color: rgba(4, 99, 134, 0.04) !important;
  }

  .no_data_section_field {
    width: 469px;
  }

  .width50 {
    width: 100%;
  }

  .Trackinput {
    width: 100%;
  }

  .next-step-btn {
    flex-direction: column;
  }

  .btn-50 {
    width: 100%;
  }

  .create-Order-form {
    padding: 32px 0;
  }

  .pickup_section {
    font-size: 20px !important;
  }
  .Nav_btn:hover {
    background-color: rgba(4, 99, 134, 0.04) !important;
  }

  .flex-col {
    flex-direction: column;
  }

  .Multi-Order-form {
    padding: 32px 16px 20px !important;
  }

  .main-header {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  .MuiButtonBase-root-MuiButton-root:hover {
    background-color: rgba(4, 99, 134, 0.04) !important;
  }

  .no_data_section_field {
    width: 469px;
  }

  /* .table-container {
    height: calc(100vh - 250px);
  } */

  .width50 {
    width: 100%;
  }

  .mb-8 {
    margin-bottom: 8px !important;
  }

  .next-step-btn {
    flex-direction: column;
  }

  .btn-50 {
    width: 100%;
  }

  .create-Order-form {
    padding: 32px 0;
  }

  .pickup_section {
    font-size: 20px !important;
  }
  .Nav_btn:hover {
    background-color: rgba(4, 99, 134, 0.04) !important;
  }

  .lognin-image {
    margin-top: 0;
    margin-bottom: 24px;
  }

  .loginlogo img {
    width: auto !important;
  }

  .lognin-image img {
    width: 155px;
  }
  /* 
  .login-section {
    width: 470px !important;
  } */

  .MuiDrawer-paper {
    width: 50%;
  }

  /* .MuiButtonBase-root:hover {
    background-color: rgba(4, 99, 134, 0.04) !important;
  } */

  .flex-col {
    flex-direction: column;
  }

  .main-header {
    font-size: 24px !important;
  }

  /* .order-form{
      margin-right:0!important;
    } */

  .Top-nav-btn {
    margin-right: 0px;
  }

  .width100 {
    width: 100% !important;
  }

  .Border-line {
    display: none;
  }

  .Track-section {
    flex-direction: column;
  }
  .Track-section .pr-64 {
    padding-right: 0;
    display: inline-block;
    width: 100%;
  }

  .Track_lbl_btn {
    margin-right: 8px;
  }

  .dottedicon {
    border: 0 !important;
    border-radius: 100% !important;
    min-width: 40px !important;
    margin-left: 8px !important;
    padding: 0 !important;
  }

  .WidthRightTrackinput {
    width: 100%;
    margin-top: 16px;
  }

  .Trackinputfield {
    width: calc(100% - 50px);
  }
}

@media only screen and (max-width: 650px) {
  .order_chkbx {
    width: 100%;
  }
  /* .chk_bx_50 {
    width: 50% !important;
  } */
}

@media only screen and (max-width: 599px) {
  .view-release-notes {
    text-align: end;
  }

  .release-notes-container {
    flex-direction: column;
  }

  .paddingbottom-0 {
    padding-bottom: 0 !important;
  }

  .Filter-section {
    gap: 8px !important;
  }
  .activeheader_lbl {
    margin-bottom: 0 !important;
  }

  .reset_btn_filter,
  .active_search_btn {
    width: 50% !important;
  }

  .reference-no-data-field {
    width: auto;
  }
  .ground-route {
    margin-right: 0 !important;
  }

  .flex-column-mobile {
    flex-direction: column;
  }

  .mat-table-call-order {
    height: calc(100vh - 410px) !important;
  }

  .Refergroup-found {
    height: 300px;
  }

  .Browse-btn {
    width: 100%;
    display: flex;
    justify-content: end;
  }
  .text-field-trackOrder {
    width: 100%;
    max-width: 100%;
  }

  .button-container {
    position: static;
  }

  .format-options {
    width: auto !important;
  }
  .Auto-height {
    height: auto !important;
  }
  .divfile {
    flex-direction: column;
  }

  .import_left-section {
    width: 100%;
  }

  .Import_btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }

  .Import_data_Section {
    flex-direction: column;
  }

  .edit-activeorder {
    height: calc(100vh - 160px);
  }
  .rate_btn {
    width: 100%;
  }

  .Rate-screen {
    height: calc(100vh - 142px);
  }

  .ml-40 {
    margin-left: 0 !important;
  }

  .Grid_button {
    display: flex;
  }
  .Batch-print-section {
    height: calc(100vh - 140px);
  }

  .margintop16 {
    margin-top: 16px !important;
  }

  .marginbottom8 {
    margin-bottom: 8px !important;
  }

  .marginbottom16 {
    margin-bottom: 16px !important;
  }

  .gap8px {
    gap: 8px !important;
  }

  .active_lbl {
    margin-bottom: 16px !important;
  }

  .side-by-side-header {
    width: 100%;
  }

  .Track-section {
    margin-top: 0 !important;
  }
  .info-text {
    display: none;
  }

  .privacy-statement {
    padding: 16px !important;
  }

  .Privacy_page_details {
    width: 100%;
  }

  .Search_btn {
    margin-left: 0 !important;
    width: 100%;
  }

  .create-Order-form {
    height: calc(100vh - 140px);
  }

  /* .Multi-Order-form {
    height: calc(100vh - 205px);
  } */

  /* .search-order-panel .MuiTableFooter-root {
    margin-bottom: 77px !important;
  } */

  .width-min-100 {
    width: 100%;
  }

  .mb-40 .MuiTableCell-footer {
    margin-bottom: 0px !important;
  }

  .new_btn {
    margin: 0 !important;
    min-width: 80px !important;
    margin-bottom: 16px !important;
  }

  .Addr_grid {
    background-color: #ffffff !important;
  }

  .width100px {
    width: 100%;
  }

  .mob-flex-col {
    flex-direction: column;
  }

  .alignment-center {
    align-items: end !important;
  }

  .choose_doc_input {
    width: 100% !important;
  }

  .Browse_btn {
    margin-top: 8px;
  }

  .privacylbl {
    margin-top: 8px;
  }

  .Footer-lbl {
    padding: 5px 20px;
  }

  .no_data_section_field {
    width: 80%;
  }

  .Flex-50 {
    width: 100%;
  }

  .Flex-row {
    flex-direction: column !important;
  }

  .dropdown-combo {
    width: 100%;
  }
  .dropdown-combo .MuiFormControl-root {
    width: 100%;
  }

  /* .table-container {
    height: calc(100vh - 310px);
  } */

  .paginator-div {
    margin-left: 10px !important;
  }
  .MuiTablePagination-toolbar {
    padding-left: 0 !important;
  }
  .MuiTablePagination-input {
    margin-left: 5px !important;
  }

  .header-sub-title {
    width: 100%;
  }

  .MuiTablePagination-input .MuiTablePagination-select {
    padding-top: 9px !important;
  }
  .paginator-icon {
    font-size: 11px !important;
    padding-right: 1px !important;
    padding-left: 4px !important;
  }

  .width100 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 599px) {
  .mob-flex-col {
    flex-direction: column;
  }

  .privacylbl {
    margin-top: 8px;
  }

  .Footer-lbl {
    padding: 5px 20px;
  }

  .no_data_section_field {
    width: 80%;
  }

  .dropdown-combo {
    width: 100%;
  }
  .dropdown-combo .MuiFormControl-root {
    width: 100%;
  }

  /* .table-container {
    height: calc(100vh - 335px);
  } */
  .Recent-delivery-grid {
    height: calc(100vh - 380px) !important; /* Adjust the height accordingly */
  }

  .paginator-div {
    margin-left: 10px !important;
  }
  .MuiTablePagination-toolbar {
    padding-left: 0 !important;
  }
  MuiTablePagination-input {
    margin-left: 5px !important;
  }

  .MuiTablePagination-input .MuiTablePagination-select {
    padding-top: 9px !important;
  }
  .paginator-icon {
    font-size: 11px !important;
    padding-right: 1px !important;
    padding-left: 4px !important;
  }

  .footer-paginator {
    gap: 14px;
  }

  .Version-block {
    display: block;
  }

  .paginator-icon svg {
    width: 18px !important;
    height: 18px !important;
  }
  .pagination p {
    font-size: 12px;
  }
  .MuiTablePagination-displayedRows {
    font-size: 12px !important;
    padding-left: 3px !important;
  }

  .MuiTablePagination-input {
    padding: 0px 12px 0px 0 !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    width: 65px;
    height: 30px;
    font-size: 13px !important;
  }

  .info_lbl {
    font-size: 15px !important;
  }
  .info_txt {
    font-size: 13px !important;
  }

  .container_login button {
    font-size: 12px;
  }
  /* .Main-Header {
    margin-top: 20px !important;
  } */

  .login-section {
    width: 100% !important;
    margin-top: 0 !important;
  }
  .Forgot_txt {
    font-size: 13px !important;
  }
  .loginchkbx_lbl {
    padding-left: 10px;
  }
  .form_info {
    flex-direction: column;
    justify-content: left;
    align-items: flex-start !important;
  }
  /* .Main-container {
    padding: 0 0 76px !important;
  } */

  .Privacy_info {
    width: 100% !important;
    right: 0;
  }
  .lognin-image {
    align-items: center;
    margin-top: 32px;
    margin-bottom: 24px;
  }

  .left-align {
    align-items: flex-start;
  }

  .loginlogo {
    margin-top: 0 !important;
  }

  .loginlogo img {
    height: auto !important;
  }

  .lognin-image img {
    height: 40px;
    width: 100%;
  }

  .MuiDrawer-paper {
    top: 73px !important;
    width: 100% !important;
  }

  .Top-nav .MuiAppBar-root {
    padding-right: 0 !important;
  }

  .Registerbtn {
    width: 80px !important;
    height: 30px !important;
    font-size: 13px !important;
    font-family: Roboto-medium !important;
  }

  .ActiveOrder .MuiTableContainer-root table thead {
    display: none;
  }

  .Card_view {
    border: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
    box-shadow: none !important;
  }

  .Field_info p:last-child {
    border-bottom: none !important;
  }

  .active-order .MuiBox-root,
  .code-form .MuiBox-root {
    flex-direction: column !important;
    align-items: flex-start;
  }

  /* 
      .Active-order .MuiTableContainer-root{
        border: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
      } */

  .Order-head {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .field_label {
    font-size: 14px;
    font-family: Roboto-bold;
    color: rgba(0, 0, 0, 0.87);
    padding-left: 10px;
    text-transform: uppercase;
    padding-bottom: 4px;
    width: 145px;
    display: inline-block;
    padding-top: 7px;
    vertical-align: top;
  }

  .Order_id {
    font-size: 14px;
    font-weight: 400;
    color: rgba(4, 99, 134, 1);
    text-decoration: underline;
  }

  .Field_info {
    border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
    display: flex;
  }

  .Field_value {
    width: calc(100% - 150px);
    display: inline-block;
    padding-top: 7px;
    padding-bottom: 4px;
    vertical-align: top;
    font-size: 14px;
  }

  .Field_value .MuiBox-root .weight-600 {
    font-family: Roboto-medium !important;
    font-weight: 500;
  }

  .Field_name {
    display: block;
  }

  b.Field_name {
    font-size: 14px;
    font-weight: Roboto-medium;
  }

  i.Field_name {
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    font-family: Roboto-regular;
  }
  .ActiveOrder .MuiTableFooter-root {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }

  /* .pagination {
        margin-bottom: 30px;
      } */

  .rateEstimateText {
    justify-content: center;
    display: flex;
    margin-right: 0px !important;
    text-align: center;
    margin-bottom: 0px !important;
  }

  .rateEstimateRes {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
}

@media only screen and (max-width: 450px) {
  .Privacy_info {
    gap: 0 !important;
  }
}

.update-date-mobile {
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
}

.update-date-time-mobile {
  color: var(--text-primary, rgba(0, 0, 0, 0.6));
}
a {
  color: #046386 !important;
  text-decoration: none;
}

.container_login {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 32px;
  display: flex;
  display: inline-flex;
}

.item {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text {
  color: #046386;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 22px;
  letter-spacing: 0.46px;
  word-wrap: break-word;
}

.lognin-image {
  padding: 0px !important;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 0;
}

.login-header {
  min-height: 72px !important;
}

.loginlogo {
  margin-top: 0;
  margin-bottom: 0 !important;
}

.lognin-image img {
  margin-top: 0 !important;
}

.lognin-image a:focus {
  border: 1px solid var(--primary-outlinedBorder, rgba(4, 99, 134, 0.5)) !important;
  display: inline-block;
  border-radius: 4px;
}

.lognin-image img:focus {
  border: 1px solid var(--primary-outlinedBorder, rgba(4, 99, 134, 0.5)) !important;
}

.lognin-image img:focus-visible {
  border: 1px solid var(--primary-outlinedBorder, rgba(4, 99, 134, 0.5)) !important;
}

.divider {
  align-self: stretch !important;
  border: 1px rgba(0, 0, 0, 0.12) solid !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.sign-in-title {
  border-radius: 4px 4px 0px 0px;
  color: #046386;
  font-size: 24px !important;
  font-weight: 400;
  word-wrap: break-word;
  background: rgba(0, 0, 0, 0.04);
  padding: 8px 16px;
}
.form-border {
  padding: 16px;
  border: 1px rgba(0, 0, 0, 0.12) solid;
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.form-border .MuiAlert-message {
  font-family: Roboto-medium !important;
}

h1 {
  margin-bottom: 0px !important;
}

.weight-500 {
  font-weight: 500 !important;
  font-family: Roboto-medium !important;
}

.font14 {
  font-size: 14px !important;
}

.addr_label {
  font-weight: 500 !important;
  font-family: Roboto-medium !important;
}

/* .MuiContainer-root {
  width: 478px !important;
} */

.Privacy_info {
  padding: 0px !important;
  display: flex;
  margin-bottom: 24px;
  justify-content: center !important;
  padding-top: 64px !important;
}

.Privacy_info button {
  font-family: Roboto-medium !important;
  font-size: 13px;
}

.text-custom {
  margin-left: 20px !important;
}

.Menu-item {
  padding: 6px 8px 6px 8px !important;
  margin-right: 16px !important;
  font-family: Roboto-medium !important;
}

.ptb-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.p16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.text-right {
  text-align: right;
}
/* Common css */

@font-face {
  font-family: "Roboto-regular";
  src: url("../fonts/Roboto-Regular.woff2") format("woff2"),
    url("../fonts/Roboto-Regular.woff") format("woff"),
    url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-bold";
  src: url("../fonts/Roboto-Bold.woff2") format("woff2"),
    url("../fonts/Roboto-Bold.woff") format("woff"),
    url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto-medium";
  src: url("../fonts/Roboto-Medium.woff2") format("woff2"),
    url("../fonts/Roboto-Medium.woff") format("woff"),
    url("../fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.Popup-header {
  font-size: 20px !important;
  font-family: Roboto-medium !important;
  line-height: 32px !important;
  padding-bottom: 0 !important;
}

.headertitle {
  font-family: Roboto-Regular !important;
  line-height: 32px;
  padding-top: 16px !important;
  padding-bottom: 8px !important;
}

.common-header {
  font-size: 24px;
  font-family: Roboto-regular;
  color: #046386;
  line-height: 32px;
}

/* Ontime performance css */

.Ontime-header {
  display: flex;
  justify-content: space-between;
  margin-top: 16px !important;
}
.Export_lbl {
  border: 1px solid var(--secondary-outlinedBorder, rgba(16, 2, 73, 0.5));
}
.Export_lbl .MuiInputBase-root .MuiSelect-select {
  padding-left: 4px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 24px !important;
}

.Export_btn {
  color: #100249 !important;
}

.Export_btn:hover {
  background-color: transparent !important;
}

/* Ontime performance css end */

/* .create-Order-form {
  height: calc(100vh - 175px);
  overflow-y: auto;
  padding: 45px 116px 0 116px;
  margin-top: 10px;
  padding-top: 10px;
} */

.h-113 {
  height: 113px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.setting-head {
  font-size: 20px !important;
  font-family: Roboto-medium !important;
}

.search-full-width {
  height: 40px;
  font-size: 15px !important;
  font-family: Roboto-Medium !important;
}

.plr-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.export_btn {
  font-size: 14px !important;
  font-family: Roboto-Medium !important;
  margin-top: 18px !important;
  margin-left: 16px !important;
}

.wh14 {
  width: 14px;
  height: 14px;
}

.w14h18 {
  width: 14px;
  height: 18px;
}

.Action_img_btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mt-32 {
  margin-top: 32px;
}
.pt-16 {
  padding-top: 16px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.font48 {
  font-size: 48px !important;
}

.align-center {
  align-items: center;
}

.mt-0 {
  margin-top: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-40 {
  margin-left: 40px;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.disabled_btn {
  color: rgba(0, 0, 0, 0.38) !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  cursor: not-allowed !important;
  border-radius: 8px !important;
}

.master-class {
  display: block;
}

.master-class-mob {
  display: none;
}

.Remove_activate {
  color: #f93549 !important;
  border: 1px solid var(--error-outlinedBorder, rgba(249, 53, 73, 0.5)) !important;
  border-radius: 8px !important;
}

.add-piecebtn {
  border-radius: 8px !important;
}

.Remove_activate:hover,
.disabled_btn:hover {
  background-color: transparent !important;
}

.ground-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 16px;
}

/* .disabled_btn:hover {
  border: none !important;
  background-color: none !important;
} */

.disabled_btn,
.add-piecebtn,
.Remove_activate {
  margin-top: 2px !important;
  padding: 6px 16px 6px 16px !important;
  line-height: 24px !important;
  font-size: 14px !important;
  font-family: Roboto-medium !important;
}

.table_chkbx {
  padding: 0 !important;
}

.add-piecebtn {
  margin-left: 16px !important;
}

.gap-16 {
  gap: 16px !important;
}

.lbl_head {
  font-size: 14px !important;
  font-family: Roboto-Medium !important;
  color: rgba(0, 0, 0, 0.6);
}

.Grid-height {
  min-height: 100px !important;
  height: 100px !important;
}

/* .MuiButtonBase-root{
  padding-top:7px!important;
  padding-bottom:3px!important;
} */

.suggestion_txt {
  font-size: 12px !important;
  font-family: Roboto-regular !important;
  letter-spacing: 0.4px !important;
  margin: 0 0 0 14px !important;
}

.border-bottom1 {
  border-bottom: none !important;
}

@media (min-width: 300px) and (max-width: 430px) and (orientation: portrait) {
  .NameSignUp {
    width: 47.2% !important;
  }
  .ContactSignUp {
    width: 100% !important;
  }
}

@media (min-width: 430px) and (max-width: 932px) and (orientation: portrait) {
  .NameSignUp {
    width: 47.5% !important;
  }
  .ContactSignUp {
    width: 100% !important;
  }
}

.ContactSignUp {
  width: 31.2%;
}

/* .MuiTypography-root {
  font-family: Roboto-regular !important;
} */

/* .MuiInputBase-input {
  color: rgba(0, 0, 0, 0.6) !important;
  padding-top: 5px !important;
} */

/* .MuiButton-root {
  font-family: Roboto-regular !important;
} */
/* Common css end */

.form_info {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 0 !important;
  margin-bottom: 8px;
}

.signin_btn {
  font-size: 15px !important;
  font-family: Roboto-medium !important;
  padding: 8px 22px 8px 22px !important;
  line-height: 26px !important;
}

.form-border .MuiCheckbox-root {
  padding-right: 5px !important;
}

.form-border .MuiTypography-root {
  font-size: 14px;
}

.info_txt {
  font-size: 14px !important;
  font-family: Roboto-medium !important;
  padding-left: 10px !important;
}
.signup_detail {
  margin-top: 24px;
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.Forgot_txt {
  font-size: 15px !important;
  font-family: Roboto-medium !important;
  padding-left: 10px;
}

.MuiDrawer-paper {
  top: 73px !important;
  width: 25%;
}

.MuiModal-backdrop {
  background-color: transparent !important;
}

/* .signup_detail{
    margin-top: 25px;
    padding-top: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  } */

.info_lbl {
  font-size: 16px !important;
  font-family: Roboto-regular !important;
}

.order_chkbx {
  flex-direction: row;
}

/* .signup_detail {
  margin-top: 25px;
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
} */

.Trace-section {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 20px;
  margin-top: 5px !important;
}

.container_login button {
  font-size: 13px;
  font-family: Roboto-medium !important;
}

.Main_head_lbl {
  padding-top: 8px;
  padding-bottom: 16px;
}
.custom-datepicker-popper {
  z-index: 9999 !important;
}
.react-datepicker-wrapper {
  width: 100%;
}

.small-cal-view .smallcal .MuiInputBase-root {
  height: 40px;
}

.small-cal-view-autocomplete .MuiInputBase-root {
  height: 40px;
}

.datetext .MuiInputBase-root {
  padding-left: 3px !important;
}

.dateiconview {
  background-color: #046386 !important;
  border: 1px solid #046386 !important;
  color: #ffffff !important;
  width: 19px;
  height: 19px;
  margin-top: 0px;
  padding-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.react-datepicker__time-container .react-datepicker__time {
  border-radius: 4px !important;
}

.react-datepicker__header {
  background-color: #ffffff !important;
}

.react-datepicker__today-button {
  background: #ffffff !important;
}

.react-datepicker__day.react-datepicker__day--028.react-datepicker__day--keyboard-selected.react-datepicker__day--today {
  background-color: #046386 !important;
  color: #ffffff !important;
  border-radius: 0 !important;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #046386 !important;
  color: #ffffff !important;
  border-radius: 0 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #046386 !important;
  color: #ffffff !important;
  border-radius: 0 !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 20px !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0;
}

.yearview {
  font-family: Roboto-medium;
  font-size: 13px;
  background: #fff;
  border: 1px solid #cacaca;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  font-family: Roboto-medium !important;
  font-size: 14px !important;
}

.react-datepicker {
  transform-origin: top left !important;
}
.custom-datepicker-popper .react-datepicker__time-container {
  width: 100% !important;
}
.react-datepicker__time-container {
  /* width: 100% !important; */
  z-index: 9999 !important;
}
.react-datepicker__time {
  width: 100%;
}
.react-datepicker__time-box {
  width: 100%;
}

.react-datepicker-popper {
  /* width: 100% !important; */
  z-index: 9999 !important;
}
.react-datepicker__today-button {
  color: #035b7e;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* @media only screen and (min-height: 860px) {
  .Privacy_info {
    position: absolute;
    bottom: 0;
  }
} */

@media only screen and (max-height: 600px) {
  .no_data_section_field {
    height: 80% !important;
    overflow-y: auto;
  }
}

/* Start Active Table CSS   */

table thead tr th {
  font-weight: 500 !important;
}

/* table thead{
  position:sticky;
  top:0;
  z-index:10000;
  background-color:white;
  border-bottom:1px solid rgba(0,0,0,0.12);
} */

.ActiveOrder {
  margin-top: 0px;
}

.active-status {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #e1ecf0 !important;
  border-radius: 100px !important;
  border: 1px solid rgba(4, 99, 134, 0.5);
  display: inline-flex;
  align-items: center;
  gap: 2px;
}

.status {
  color: #035b7e;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  line-height: 1.43 !important;
  letter-spacing: 0.17px !important;
  word-wrap: break-word;
}

.active-order .MuiTableCell-head {
  vertical-align: top !important;
  padding: 8px !important;
  font-size: 14px;
  font-family: Roboto-medium !important;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  position: sticky;
  top: 0;
  background-color: white;
}

.Piecesgrid .MuiTableCell-body {
  vertical-align: middle !important;
}

.grid_chkbx {
  padding: 0 !important;
}

.data-no-found {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  border-bottom: none !important;
}

.commodity-header thead tr th {
  padding: 0 3px;
  height: 40px;
}

.commodity-table tr td {
  padding: 0 3px;
  height: 40px;
}

.refer-no-data {
  height: 126px;
}

.mr-16 {
  margin-right: 16px;
}

/* .order-form{
  margin-right:-10px!important;
} */

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pagination p {
  margin: 0px !important;
}

.Dialog-content {
  padding-bottom: 0 !important;
}

.pagination .MuiTableCell-root {
  padding: 0 0px !important;
}

.import_no_data {
  height: calc(100vh - 370px);
}

.blue-btn {
  font-size: 15px !important;
  font-family: Roboto-medium !important;
}

.blue-btn .MuiButton-icon img {
  margin-bottom: 1px;
}

.Nodata_found .MuiTableCell-root {
  border-bottom: none !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MuiTablePagination-input {
  padding: 4px 20px 4px 0 !important;
  border: 1px solid
    var(--components-input-outlined-enabledBorder, rgba(0, 0, 0, 0.23));
  background-color: transparent !important;
  border-radius: 4px;
  font-size: 16px !important;
  width: 80px;
  height: 40px;
}

.MuiTablePagination-input svg {
  margin-right: 5px;
}

.MuiTablePagination-input .MuiTablePagination-select {
  background-color: transparent !important;
  padding-right: 16px !important;
  padding-left: 0;
}

.Footer-lbl {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer-paginator {
  display: flex !important;
  justify-content: center !important;
}

.footer-paginator .MuiTypography-root {
  font-size: 10px;
  font-family: Roboto-medium !important;
  line-height: 14px;
}

.wrap-text {
  white-space: wrap !important;
}

/* .dropdown-combo {
  margin-top: 15px;
} */

.privacylbl {
  color: #fff !important;
  font-size: 13px !important;
  margin-left: 20px !important;
  text-transform: uppercase;
}

.status-info {
  background-color: var(--blue-50, rgba(225, 236, 240, 1));
  border: 1px solid var(--primary-outlinedBorder, rgba(4, 99, 134, 0.5));
  color: var(--primary-dark, rgba(3, 91, 126, 1));
  font-style: normal;
  padding: 2px 10px;
  border-radius: 100px;
}

.ActiveOrder .MuiTableFooter-root {
  display: flex;
  justify-content: end;
  padding-top: 16px;
  padding-bottom: 16px;
}

.ActiveOrder .MuiTableCell-footer,
.Rate-table .MuiTableCell-footer {
  border-bottom: none !important;
}
/* End Active Table CSS   */
/* 
.MuiButtonBase-root:hover{
  background-color: rgba(4, 99, 134, 0.04)!important;
} */

.Header-bar {
  height: 65px;
}

.Top-nav-btn {
  width: 40px;
}
.sidebar {
  padding-bottom: 80px;
}

/* .css-1160xiw-MuiPaper-root-MuiDrawer-paper{
    top:64px!important
  } */

/* .sidebar ul li{
    font-size:14px;
    font-weight:400;
  } */

.sidebar ul li .MuiListItemButton-root {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 25px;
}

.sidebar
  ul
  li
  .MuiListItemButton-root
  .MuiListItemText-root
  .MuiTypography-root {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
}

.sidebar ul li a {
  font-family: Roboto-regular !important;
  color: rgba(0, 0, 0, 0.87) !important;
  padding-left: 25px !important;
  text-decoration: none !important;
}

.sidebar ul li a .MuiListItemText-root .MuiTypography-root {
  font-size: 14px !important;
}
.sidebar ul li:first-child {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}

.Registerbtn {
  border: 1px solid var(--primary-outlinedBorder, #04638680) !important;
  width: 100px;
  height: 36px;
  font-family: Roboto-medium !important;
}

.MuiDrawer-root {
  position: unset !important;
}

/* Active Order CSS */

/* .Main-container {
  padding: 0 0 34px !important;
} */

.ActiveOrder,
.Rate-table {
  box-shadow: none !important;
}

.Main-Header {
  font-size: 24px !important;
}

.active-order .MuiTablePagination-root {
  border-bottom: none !important;
}

.MuiTablePagination-menuItem:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.No-data-section {
  background-color: rgba(0, 0, 0, 0.04);
  width: 100%;
  height: 100%;
}

.no_data_section_field {
  width: 608px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 8px 16px 16px;
  display: inline-block;
}

.no-data-header {
  font-size: 24px;
  font-family: Roboto-regular;
  font-weight: 400;
  float: left;
  width: 100%;
  text-align: left;
}

.no-data-para {
  float: left;
  width: 100%;
  text-align: left;
  margin-top: 6px;
  font-size: 16px;
  font-family: Roboto-regular;
}

.next-step-btn {
  float: left;
  width: 100%;
  margin-bottom: 0px !important;
}

.btn-50 {
  width: 50%;
}

.mr-16 {
  margin-right: 16px !important;
}

.MuiTablePagination-toolbar {
  min-height: 40px !important;
}

.width700 {
  min-width: 700px !important;
}

.width85 {
  min-width: 85px;
}

.width94 {
  min-width: 94px !important;
}

/* Long Order Form css */

.h-100 {
  height: 100px;
}

.table-fixed {
  display: table !important;
  table-layout: fixed !important;
  width: 100% !important;
  padding: 0px;
}

/* .ptb-0{
  padding-top:0!important;
  padding-bottom:0!important;
} */

.dropdown-grid,
.dropdown-grid tbody,
.dropdown-grid thead,
.Table-head thead tr {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  opacity: 1 !important;
}

.thead-cell {
  color: rgba(0, 0, 0, 0.87) !important;
}

.table-fixed th,
.table-fixed td {
  padding: 0;
  height: 36px;
}

.width104 {
  width: 104px;
}

.width110 {
  width: 110px;
}

.width300 {
  width: 300px;
}

.width200 {
  width: 200px;
}

.width230 {
  width: 230px;
}

.Info-wrap {
  overflow-wrap: break-word;
  white-space: normal;
}

.width180 {
  width: 180px;
}

.width94p {
  width: 94px;
}

.test {
  min-width: 200px !important;
  width: 200px !important;
}

.main-header {
  line-height: 56px;
}

.Sub-header {
  font-size: 20px !important;
  font-family: Roboto-medium !important;
  padding-bottom: 8px !important;
}

.addresslookup-grid {
  /* min-height: 200px;
  max-height:200px; */
  overflow-y: auto !important;
  height: calc(100% - 200px);
}

.pickup_section {
  margin-top: 16px !important;
  margin-left: 8px !important;
  font-size: 24px !important;
}

.Search_btn {
  padding: 6px 16px 6px 16px !important;
  font-size: 14px !important;
  font-family: Roboto-medium !important;
  border-radius: 8px !important;
  height: 36px;
  margin-top: 2px !important;
}

.Routesec-1 {
  display: flex;
  flex-direction: column;
}

.flight_id {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 24px;
  font-family: Roboto-regular;
}

.colflexview {
  flex-direction: column;
}

.code-form {
  padding-bottom: 8px;
  border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
  margin-bottom: 16px;
  /* padding-top:16px; */
}

/* .grid-border{
  border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12))
} */

.sub-infolbl {
  font-size: 14px !important;
  font-family: Roboto-medium !important;
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
}

.sub-header-lbl {
  font-size: 14px !important;
  font-family: Roboto-medium !important;
  color: rgba(0, 0, 0, 0.87);
}

.txt_info_lbl {
  font-size: 14px !important;
  font-family: Roboto-regular !important;
  color: rgba(0, 0, 0, 0.87);
}

.textcolor {
  color: rgba(0, 0, 0, 0.6);
}

.mt-16 {
  margin-top: 16px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-32 {
  padding-bottom: 32px;
}

.mt-40 {
  margin-top: 40px !important;
}

.align-flex-start {
  align-items: flex-start;
}

.mb-50 {
  margin-bottom: 50px;
}

.detail-info-section {
  padding: 16px;
}

.Divider {
  border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
}

.Divider-top {
  border-top: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
}

.Border-divider {
  border-top: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
}

.properlbl {
  font-size: 14px;
  font-family: Roboto-Regular;
}

.page-footer .MuiTableFooter-root .pagination .MuiTableCell-footer {
  border-bottom: none !important;
}

/* Long Order Form css end */

.main-header {
  line-height: 56px;
}

.pickup_section {
  margin-top: 16px !important;
  margin-left: 8px !important;
  font-size: 24px !important;
}

.input-label {
  font-family: Roboto-regular;
}

.gap-8 {
  gap: 8px !important;
}

.gap-12 {
  gap: 12px !important;
}

.mb-50 {
  margin-bottom: 50px;
}

.Add-icon {
  width: 40px;
  height: 40px;
}

.width-10 {
  width: 10%;
}

.width-90 {
  width: 90%;
}

.package-header {
  font-size: 16px !important;
  font-family: Roboto-Regular !important;
  line-height: 175% !important;
}

.MuiButton {
  font-size: 15px !important;
  font-family: Roboto-Medium !important;
  border-radius: 8px !important;
}

.Action-btn {
  width: 82px;
  height: 42px;
}

.padding0 {
  padding: 0 !important;
}

.width50 {
  width: 50%;
}

.width33 {
  width: 33.33%;
}

.react-datepicker__triangle {
  left: 35px !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  fill: #ffffff !important;
  color: #ffffff !important;
}

/* Route Preview Design start */

.phonenum {
  font-size: 16px;
  font-family: Roboto-regular !important;
  color: #046386;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.Phone-num-lbl {
  display: flex;
  align-items: center;
}
.comp_name {
  font-size: 14px;
  font-family: Roboto-medium !important;
  color: rgba(0, 0, 0, 0.87);
}

.comp_name_dtl {
  font-size: 14px;
  font-family: Roboto-regular !important;
  color: rgba(0, 0, 0, 0.87);
}

.map-lbl {
  font-size: 12px;
  font-family: Roboto-regular !important;
  line-height: 19.92px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0;
}

.Route-preview-screen {
  height: calc(100vh - 193px);
  overflow-y: auto;
}

.date-lbl {
  font-size: 16px;
  font-family: Roboto-regular !important;
  color: rgba(0, 0, 0, 0.87);
  line-height: 24px;
  letter-spacing: 0.15px;
}

.pick_lbl {
  font-size: 14px;
  font-family: Roboto-regular;
  line-height: 20px;
}

.arrow-icon {
  width: 20px;
  height: 20px;
  color: rgba(0, 0, 0, 0.56);
}

.route-head {
  line-height: 24px !important;
  padding: 8px;
  letter-spacing: 0.15px !important;
  color: rgba(0, 0, 0, 0.87);
}

.gm-style-iw-ch {
  display: none;
}

.gm-style .gm-style-iw-tc {
  display: none !important;
}
.gm-style .gm-style-iw-c {
  width: 270px;
  max-height: 500px !important;
  height: auto !important;
  min-height: auto !important;
  margin-top: 25px !important;
  box-shadow: 0px 5px 5px -3px #00000033;
  box-shadow: 0px 8px 10px 1px #00000024;
  box-shadow: 0px 3px 14px 2px #0000001f;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.gm-style-iw-chr button {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  right: 5px;
  top: 5px;
}

.gm-style-iw-chr button span {
  margin: 0 !important;
}

.map-pinpoint {
  margin-top: 10px;
}

.gm-style-iw-d {
  max-height: 100% !important;
}

.assign-routelbl {
  color: #046386;
  text-decoration: underline;
  font-size: 14px;
  font-family: Roboto-regular;
  letter-spacing: 0.17px;
}

.route-headlbl1 {
  font-size: 16px;
  font-family: Roboto-medium;
  color: rgba(0, 0, 0, 0.6);
  line-height: 24px;
  margin-bottom: 4px;
}

.other_dtl_summary {
  font-family: Roboto-regular !important;
  font-size: 14px !important;
  line-height: 20.02px !important;
  letter-spacing: 0.17px !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.loader-icon {
  display: flex;
  justify-content: center;
}

.loadtertxt {
  font-size: 20px;
  font-family: Roboto-medium !important;
  line-height: 32px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
  align-items: center;
}

.loader_dtl {
  font-size: 16px;
  font-family: Roboto-regular;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  text-align: center;
}

.Route-status-section .Loader-section {
  background-color: rgba(0, 0, 0, 0.04);
}

.justifycontentend {
  justify-content: flex-end;
}

.Border-right-lbl {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.Left-route-section {
  flex-direction: column;
}

.no-route-txt {
  font-size: 24px;
  font-family: Roboto-regular !important;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.87);
}

.route-text-lbl {
  margin-bottom: 0;
  font-size: 16px;
  font-family: Roboto-regular !important;
  margin-bottom: 32px;
  margin-top: 16px;
}

.assign-route {
  color: #046386;
  background-color: transparent !important;
  border: 0 !important;
  border: 1px solid rgba(4, 99, 134, 0.5) !important;
  border-radius: 8px !important;
  padding: 7px 22px !important;
  font-size: 15px !important;
  font-family: Roboto-medium !important;
  line-height: 26px !important;
  letter-spacing: 0.46px !important;
  text-transform: uppercase;
}

.logo-img {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-img img {
  width: 48px;
  height: 48px;
}

.ground-img img {
  width: 32px;
  height: 32px;
}

.border-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 50px; /* Adjust as necessary to control spacing between the image and surrounding text */
}

.car-drop {
  margin: 0 17px;
}

.bordered-image {
  display: block;
  position: relative;
  z-index: 1;
}

.car-drop::before,
.car-drop::after {
  width: 15px !important;
}
.car-drop::before {
  left: -17px !important;
}

.car-drop::after {
  right: -17px !important;
}

.border-wrapper::before,
.border-wrapper::after {
  content: "";
  width: 50px;
  border-top: 1px dashed rgba(0, 0, 0, 0.42);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}

.border-wrapper::before {
  left: -50px;
  border-left: 1px dashed rgba(0, 0, 0, 0.42);
}

.border-wrapper::after {
  right: -50px;
  border-right: 1px dashed rgba(0, 0, 0, 0.42);
}

.ml-20 {
  margin-left: 20px;
}

.Routesec-2 {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
}

.flight-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flex-end {
  justify-content: flex-end;
}

.del_date {
  font-size: 12px;
  font-family: Roboto-regular;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  letter-spacing: 0.4px;
}

.del_time {
  font-size: 16px;
  font-family: Roboto-regular;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}

.location_lbl {
  color: rgba(0, 0, 0, 0.38);
}

.flight-route {
  justify-content: space-between;
}

.flight-bcg {
  background-color: rgba(93, 84, 84, 0.04);
}

.mt4 {
  margin-top: 4px;
}

.green-val {
  font-size: 20px;
  font-family: Roboto-medium;
  line-height: 32px;
  letter-spacing: 0.15px;
  color: #008653;
}

.black-val {
  font-size: 20px;
  font-family: Roboto-medium;
  line-height: 32px;
  letter-spacing: 0.15px;
  color: #000000de;
}
.red-val {
  font-size: 20px;
  font-family: Roboto-medium;
  line-height: 32px;
  letter-spacing: 0.15px;
  color: #f51621;
}

.Routesec-3 {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 4px;
}

/* Dots at the ends */
.border-wrapper::before {
  content: "";
}

.car-drop-left {
  left: -23px !important;
}

.car-drop-right {
  right: -23px !important;
}

.border-wrapper-dot-left,
.border-wrapper-dot-right {
  content: "";
  width: 6px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.42);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2; /* Ensure the dots are above the border */
}

.border-wrapper-dot-left {
  left: -56px; /* Position the dot before the start of the border */
}

.border-wrapper-dot-right {
  right: -56px; /* Position the dot at the end of the border */
}

.route-left {
  font-size: 12px;
  font-family: Roboto-regular;
  line-height: 20px;
  color: #000000;
}

.label-container {
  position: absolute;
  left: -62px; /* Adjust as needed to position relative to the dot */
  top: -27px; /* Adjust as needed to position above the dot */
  z-index: 1; /* Ensure the label appears above other elements */
}

.label-container-right {
  position: absolute;
  right: -62px;
  top: -27px;
  z-index: 1;
}

:root {
  --body-background-color: #e5f4ff;
  --v-progress-left: 50px;
  --v-progress-item-height-width: 10px;
  --v-progress-line-height: 48px;
  --v-progress-line-width: 1px;
  --v-progress-gap: 13px;
  --blue: #0081c9;
  --green: #159895;
  --light-blue: #5bc0f8;
}
/* vertical progress */

.v-progress {
  display: none;
}

/* Label styling */
.flight-label {
  font-size: 12px; /* Adjust font size as needed */
  color: rgba(0, 0, 0, 0.38); /* Text color */
  position: relative;
  font-family: Roboto-regular;
  line-height: 20px;
}

.flight-route-time {
  width: 39%;
}

.route-flight {
  width: 22%;
}

/* Route Preview Design End */

/* Rate Estimation */

.rate-lbl {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-family: Roboto-regular;
  line-height: 28px;
  letter-spacing: 0.15px;
  padding-bottom: 8px;
}

.greycode {
  color: rgba(0, 0, 0, 0.6) !important;
}

.MuiMenuItem-root {
  color: rgba(0, 0, 0, 0.6) !important;
}

.Rateorder_btn {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.38) !important;
  font-family: Roboto-medium !important;
  font-size: 14px;
}

/* End */

@media only screen and (min-width: 1550px) {
  .route-flight {
    width: 36%;
  }

  .flight-route-time {
    width: 32% !important;
  }
  .border-wrapper::before,
  .border-wrapper::after {
    width: 140px;
  }

  .border-wrapper::before {
    left: -140px;
  }

  .border-wrapper::after {
    right: -140px;
  }

  .border-wrapper-dot-left {
    left: -145px;
  }

  .border-wrapper-dot-right {
    right: -145px;
  }

  .label-container {
    left: -135px;
  }

  .label-container-right {
    right: -135px;
  }

  .label-container {
    left: -130px;
  }

  .prefer-route-left {
    width: 70%;
  }

  .Routesec-2 {
    width: 95%;
  }

  .info-route-left {
    margin-right: 90px;
  }
  .info-route-right {
    margin-left: 100px !important;
  }
}

@media only screen and (max-width: 1050px) {
  .order_chkbx {
    flex-direction: column;
  }

  .Table-fixed {
    table-layout: fixed;
  }
}
@media only screen and (max-width: 905px) {
  .Danger-good {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .piecebtn {
    width: 110px !important;
  }

  .master-class-mob {
    display: block;
  }

  .master-class {
    display: none;
  }

  .disabled_btn {
    width: 90px !important;
  }
  .service_package_section {
    margin-top: -110px !important;
  }

  /* .import_no_data_field {
    position: static !important;
    transform: none !important;
    width: auto;
  } */

  .border-bottom1 {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12)) !important;
    margin-left: 16px;
  }

  .import_no_data {
    height: calc(100vh - 420px);
  }

  .orderhead {
    margin-bottom: 16px !important;
  }
  .login-header {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  /* .Nodata_found {
    height: calc(100vh - 320px);
  } */

  .MuiButtonBase-root-MuiButton-root:hover {
    background-color: rgba(4, 99, 134, 0.04) !important;
  }

  .No_data {
    display: inline-block;
  }

  .no_data_section_field {
    width: 469px;
  }

  /* .table-container {
    height: calc(100vh - 310px);
  } */

  .width50 {
    width: 100%;
  }

  .mb-8 {
    margin-bottom: 8px !important;
  }

  .next-step-btn {
    flex-direction: column;
  }

  .btn-50 {
    width: 100%;
  }

  .create-Order-form {
    padding: 32px 16px;
  }

  .pickup_section {
    font-size: 20px !important;
    font-family: Roboto-medium !important;
  }
  .Nav_btn:hover {
    background-color: rgba(4, 99, 134, 0.04) !important;
  }

  .lognin-image {
    margin-top: 0;
    margin-bottom: 24px;
  }

  .loginlogo img {
    width: auto !important;
  }

  .lognin-image img {
    width: 155px;
  }
  /* 
  .login-section {
    width: 470px !important;
  } */

  .MuiDrawer-paper {
    width: 50%;
  }

  .Border-divider {
    border-top: 0px solid transparent !important;
  }

  /* .create-Order-form {
    padding-left: 30px !important;
    padding-right: 30px !important;
  } */

  /* .MuiButtonBase-root:hover {
    background-color: rgba(4, 99, 134, 0.04) !important;
  } */

  .flex-col {
    flex-direction: column;
  }

  .main-header {
    font-size: 24px !important;
  }

  /* .order-form{
      margin-right:0!important;
    } */

  .Top-nav-btn {
    margin-right: 0px;
  }

  .width100 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 599px) {
  .Rate-estimatebtn {
    padding: 16px !important;
    flex-direction: column !important;
    padding-top: 8px !important;
  }

  .connecting-flight-section {
    display: none;
  }

  .full-width-mb {
    width: 100%;
  }

  .mob-bott-8 {
    margin-bottom: 8px;
  }

  .mobleft-0 {
    margin-left: 0 !important;
  }

  .full-width-xs {
    width: 100%;
    text-align: end;
  }

  .Border-right-lbl {
    border-right: 0;
    padding-right: 0 !important;
  }

  .active-order-mobile {
    margin-bottom: 16px;
  }

  .import_no_data {
    height: calc(100vh - 370px);
  }

  .route-lbl {
    font-size: 16px;
    font-family: Roboto-regular !important;
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 0.15px;
    line-height: 24px;
  }

  .route-live-status {
    font-size: 14px;
    font-family: Roboto-medium;
    line-height: 21.98px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.87);
    display: block;
  }

  .route-live-lbl {
    font-size: 12px;
    font-family: Roboto-regular;
    line-height: 19.92px;
    letter-spacing: 0.4px;
    color: rgba(0, 0, 0, 0.6);
  }

  .v-progress {
    display: inline-block;
  }

  .v-progress ul {
    list-style: none;
    padding-left: 0;
  }

  li.v-progress-item.completed {
    padding-bottom: 20px;
  }

  .v-progress-item {
    position: relative;
    /* left: var(--v-progress-left); */
    margin-left: var(--v-progress-left);
    height: var(--v-progress-item-height-width);
    line-height: var(--v-progress-item-height-width);
    margin-bottom: var(--v-progress-line-height);
    --v-progress-border: 8px;
  }

  .v-progress-item:last-child {
    margin-bottom: 0px;
  }

  .v-progress-item:last-child:after {
    border-left: 0px;
  }

  .v-progress-item:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: var(--v-progress-item-height-width);
    height: var(--v-progress-item-height-width);
    left: calc(0px - var(--v-progress-left));
    border-radius: 50%;
    background-color: #00000033;
    top: 6px;
  }

  .v-progress-item:after {
    content: "";
    display: inline-block;
    position: absolute;
    height: calc(var(--v-progress-line-height) - var(--v-progress-gap));
    top: 23px;
    left: calc(
      0px - var(--v-progress-left) + var(--v-progress-item-height-width) / 2 -
        var(--v-progress-line-width) / 2
    );
    border-left: var(--v-progress-line-width) solid #00000033;
  }

  .v-progress-item.completed:after {
    border-color: #00000033;
    top: 31px;
  }

  .v-progress-item.completed:before {
    content: url("../images/trackorder/map-pin.svg");

    text-align: center;
    background-color: transparent;
    height: calc(
      var(--v-progress-border) + var(--v-progress-item-height-width)
    );
    width: calc(var(--v-progress-border) + var(--v-progress-item-height-width));
    line-height: calc(
      var(--v-progress-border) + var(--v-progress-item-height-width)
    );
    left: calc(0px - var(--v-progress-left) - var(--v-progress-border) / 2);
    top: 4px;
  }

  .v-progress-item.delivered:after {
    border-color: #00000033;
    top: 31px;
  }

  .v-progress-item.delivered:before {
    content: url("../images/trackorder/flag-pin.svg");

    text-align: center;
    background-color: transparent;
    height: calc(
      var(--v-progress-border) + var(--v-progress-item-height-width)
    );
    width: calc(var(--v-progress-border) + var(--v-progress-item-height-width));
    line-height: calc(
      var(--v-progress-border) + var(--v-progress-item-height-width)
    );
    left: calc(0px - var(--v-progress-left) - var(--v-progress-border) / 2);
    top: 6px;
  }

  .v-progress-item.inprogress:before {
    background-color: white;
    /*   height: calc(var(--v-progress-border) + var(--v-progress-item-height-width));
	width: calc(var(--v-progress-border) + var(--v-progress-item-height-width)); */
    outline: calc(var(--v-progress-border) / 2) solid var(--blue);
    top: calc(0px - var(--v-progress-border) + var(--v-progress-border));
  }

  .Routesec-2 {
    display: none;
  }

  .flight-progress {
    margin-left: 20px;
    margin-bottom: 30px;
  }

  .service_package_section {
    margin-top: 0px !important;
  }

  .mob-flex-col {
    flex-direction: column;
  }

  .privacylbl {
    margin-top: 8px;
  }

  .Footer-lbl {
    padding: 5px 20px;
  }

  .no_data_section_field {
    width: 80%;
  }

  .dropdown-combo {
    width: 100%;
  }
  .dropdown-combo .MuiFormControl-root {
    width: 100%;
  }

  /* .table-container {
    height: calc(100vh - 335px);
  } */

  .paginator-div {
    margin-left: 10px !important;
  }
  .MuiTablePagination-toolbar {
    padding-left: 0 !important;
  }
  MuiTablePagination-input {
    margin-left: 5px !important;
  }

  .MuiTablePagination-input .MuiTablePagination-select {
    padding-top: 9px !important;
  }
  .paginator-icon {
    font-size: 11px !important;
    padding-right: 1px !important;
    padding-left: 4px !important;
  }

  .footer-paginator {
    gap: 14px;
  }

  .Version-block {
    display: block;
  }

  .paginator-icon svg {
    width: 18px !important;
    height: 18px !important;
  }
  .pagination p {
    font-size: 12px;
  }
  .MuiTablePagination-displayedRows {
    font-size: 12px !important;
    padding-left: 3px !important;
  }

  .MuiTablePagination-input {
    padding: 0px 12px 0px 0 !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    width: 65px;
    height: 30px;
    font-size: 13px !important;
  }

  .info_lbl {
    font-size: 15px !important;
  }
  .info_txt {
    font-size: 13px !important;
  }

  .container_login button {
    font-size: 12px;
  }
  /* .Main-Header {
    margin-top: 16px !important;
  } */

  .login-section {
    width: 100% !important;
    margin-top: 0 !important;
  }
  .Forgot_txt {
    font-size: 13px !important;
  }
  .loginchkbx_lbl {
    padding-left: 10px;
  }
  .form_info {
    flex-direction: column;
    justify-content: left;
    align-items: flex-start !important;
  }
  /* .Main-container {
    padding: 0 0 76px !important;
  } */

  .Privacy_info {
    width: 100% !important;
    right: 0;
  }
  .lognin-image {
    align-items: center;
    margin-top: 32px;
    margin-bottom: 24px;
  }

  .left-align {
    align-items: flex-start;
  }

  .loginlogo {
    margin-top: 0 !important;
  }

  .loginlogo img {
    height: auto !important;
  }

  .account-16 {
    margin-bottom: 16px !important;
  }

  .lognin-image img {
    height: 40px;
    width: 100%;
  }

  .MuiDrawer-paper {
    top: 73px !important;
    width: 100% !important;
  }

  .Top-nav .MuiAppBar-root {
    padding-right: 0 !important;
  }

  .Registerbtn {
    width: 80px !important;
    height: 30px !important;
    font-size: 13px !important;
    font-family: Roboto-medium !important;
  }

  /* .active-order{
        padding-left:0!important;
        padding-right:0!important;
      } */

  .Pieces-grid-table .MuiTableContainer-root table thead {
    display: table-header-group !important;
  }

  .ActiveOrder .MuiTableContainer-root table thead {
    display: none;
  }

  .Rate-table .MuiTableContainer-root table thead {
    display: table-header-group !important;
  }

  .Card_view {
    border: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
    box-shadow: none !important;
  }

  .Field_info p:last-child {
    border-bottom: none !important;
  }

  .active-order .MuiBox-root {
    flex-direction: column !important;
    align-items: flex-start;
  }

  /* 
      .Active-order .MuiTableContainer-root{
        border: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
      } */

  .Order-head {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .field_label {
    font-size: 14px;
    font-family: Roboto-bold;
    color: rgba(0, 0, 0, 0.87);
    padding-left: 10px;
    text-transform: uppercase;
    padding-bottom: 4px;
    width: 145px;
    display: inline-block;
    padding-top: 7px;
    vertical-align: top;
  }

  .Order_id {
    font-size: 14px;
    font-weight: 400;
    color: rgba(4, 99, 134, 1);
    text-decoration: underline;
  }

  .Field_info {
    border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
    display: flex;
  }

  .Field_value {
    width: calc(100% - 150px);
    display: inline-block;
    padding-top: 7px;
    padding-bottom: 4px;
    vertical-align: top;
    font-size: 14px;
  }

  .Field_value .MuiBox-root .weight-600 {
    font-family: Roboto-medium !important;
    font-weight: 500;
  }

  .Field_name {
    display: block;
  }

  b.Field_name {
    font-size: 14px;
    font-weight: Roboto-medium;
  }

  i.Field_name {
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    font-family: Roboto-regular;
  }
  .ActiveOrder .MuiTableFooter-root {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }

  /* .pagination {
        margin-bottom: 30px;
      } */
}

.update-date-mobile {
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
}

.update-date-time-mobile {
  color: var(--text-primary, rgba(0, 0, 0, 0.6));
}

@media (max-width: 600px) {
  .file-upload-container {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* Forgot Password */
.forgot-password {
  font-size: 15px !important;
  font-family: Roboto-medium !important;
  padding: 8px 22px 8px 22px !important;
  line-height: 26px !important;
}

.forgot-password-title {
  border-radius: 4px 4px 0px 0px;
  color: #046386 !important ;
  font-size: 24px !important;
  font-family: Roboto-regular;
  word-wrap: break-word;
  background: rgba(0, 0, 0, 0.04);
  padding: 8px;
}
.Forgot-password-form-border {
  border: 1px rgba(0, 0, 0, 0.12) solid;
  border-radius: 4px;
}

.Forgot-password-statement {
  font-size: 16px !important;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.terms-and-conditions-statement {
  font-size: 17.5px !important;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
/* .forgot-password-section {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
} */

/* .footerPrivacy {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.change-password-section {
  width: 608px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  margin-top: 32px !important;
  /* height: 100vh; */
}
.change-password-title {
  border-radius: 4px 4px 0px 0px;
  color: #046386 !important ;
  font-size: 24px !important;
  font-weight: 400 !important;
  word-wrap: break-word;
  background: rgba(0, 0, 0, 0.04);
  padding: 8px;
}

.change-password-form-border {
  padding: 12px;
  border: 1px rgba(0, 0, 0, 0.12) solid;
  border-radius: 4px;
  border-radius: 4px;
}
.change-password-statement {
  padding-bottom: 10px !important;
  font-size: 16px !important;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.head_lbl_header {
  font-size: 16px !important;
  font-family: Roboto-regular !important;
  line-height: 24px !important;
  color: rgba(0, 0, 0, 0.6);
}
.demm_slass {
  font-size: 16px !important;
  font-family: Roboto-regular !important;
  line-height: 24px !important;
  color: rgba(0, 0, 0, 0.6);
}

.info-dots {
  width: 5px !important;
  height: 5px !important;
  margin-right: 5px;
  color: #00677e;
}
.change-password-instructions {
  color: #00677e !important;
  font-family: Roboto-regular !important;
}
.instructions-title {
  font-weight: 500 !important;
  font-family: Roboto-medium !important;
}
.Txtbx-forgot-password label {
  font-size: 14px !important;
  font-family: Roboto-regular !important;
}
.Txtbx-forgot-password {
  margin-bottom: 0px !important;
}
.change-password-btn {
  border-radius: 8px !important;
  font-size: 15px !important;
  font-family: Roboto-medium !important;
  padding: 8px 22px 8px 22px !important;
  line-height: 26px !important;
}
.password-error {
  margin-top: 5px !important;
}

/* responsive---Change Password */
@media (min-width: 430px) and (max-width: 932px) and (orientation: portrait) {
  .change-password-section {
    width: 90% !important;
  }
  .change-password-title {
    font-size: 20px !important;
  }
}

@media (min-width: 314px) and (max-width: 430px) and (orientation: portrait) {
  .change-password-section {
    width: 95% !important;
  }
  .change-password-title {
    font-size: 20px !important;
  }
  .change-password-btn {
    font-size: 13px !important;
  }
}
.security-notification {
  height: 156px;
  padding: 6px, 16px, 6px, 16px;
}
.security-notification {
  height: 156px;
  padding: 6px, 16px, 6px, 16px;
}
.alert-title {
  font-family: Roboto-medium !important;
}
.alert {
  padding: 0px !important;
  margin-top: 15px !important;
  color: #e0f2f5;
}
.alert-info {
  color: #00677e !important;
}
.back-icon {
  width: 27px !important;
  height: 27px !important;
  color: #046386;
}
.back-icon-text {
  color: #046386;
  font-weight: Roboto-medium !important;
  font-size: 16px !important;
}
.alert-submit {
  font-weight: 500 !important;
  color: #005629 !important;
  font-size: 14px !important;
}

.Back-btn {
  padding: 16px 11px;
}

/* OnTime Performane */
.table-container1 {
  overflow-y: auto;
  height: calc(100vh - 399px);
}

.no-data-border {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px !important;
  padding: 16px !important;
  width: 60%;
  margin: auto !important;
  text-align: justify;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.no-data-border-bold {
  font-size: 24px;
}

.exportdata-grid {
  height: calc(100vh - 200px);
}

.table-section {
  margin: auto !important;
  height: calc(100vh - 445px);
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.04) !important;
  position: relative;
}
.format-options {
  width: 290px !important;
}
.export-btn {
  border: 1px solid rgba(16, 2, 73, 0.5) !important;

  border-radius: 8px !important;
  color: black !important;
  padding-right: 5px !important;
}

/* .dropdown-icon {
  margin-left: 5px;
  color: black;
} */

/* .select-dropdown {
  width: 220px !important;
  height: 40px !important;
  margin-right: 16px !important;
}
.date-pickers {
  display: flex;
  gap: 1rem;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-bottom: 0px !important;
}
.Date-picker {
  width: 220px;
  height: 40px !important;
}
.ontime-title {
  margin-top: 16px !important;
  font-size: 24px !important;
}
.select-row {
  display: flex;
}
.Select-box {
  display: flex;
  margin-top: 1rem;
  padding-left: 16px !important;
  padding-bottom: 1rem !important;
}
.export-btn {
  border: 1px solid rgba(16, 2, 73, 0.5) !important;
  padding: 6px, 16px, 6px, 16px !important;
  margin-top: 16px !important;
  border-radius: 8px !important;
  color: black !important;
}
.export-dropdown {
  width: 111px !important;
  height: 40px !important;
  color: black;
  border: 1px solid rgba(16, 2, 73, 0.5) !important;
}
.content-ontime {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px !important;
}
.header-1 {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Optional: aligns items vertically center */
/* padding-bottom: 8px !important;
}
.display-report-btn {
  border-radius: 8px !important;
  height: 40px !important;
}
.container-fluid .OnTimePerformance {
  padding: 8px !important;

  width: 1232px !important;
} */
/* .report-criteria {
  height: 40px !important;
  padding: 8px;
  line-height: 40px !important;
  margin-bottom: 16px;
}
.report-criteria-text {
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: Roboto-medium !important;
  padding: 8px !important;
}
.no-data-border-bold {
  font-size: 24px;
}
.dropdown-icon {
  margin-left: 5px;
  color: black;
}
.no-data-border {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px !important;
  padding: 16px !important;
  width: 608px;
  margin: auto !important;
  text-align: justify;
}
.icon-small {
  font-size: 18px !important;
}
.table-section {
  margin: auto !important;
  height: calc(100vh - 455px);
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.table-head-data {
  margin-top: 150px !important;
}
.select-dropdown-format {
  width: 220px;
  height: 40px !important;
  margin-right: 16px !important;
}

.format-options {
  width: 295px !important;
} */
/* @media (min-width: 768px) and (max-width: 1366px) and (orientation: portrait) {
  .Date-picker {
    width: 50% !important;
    height: 40px !important;
    gap: 1rem !important;
  }
  .ontime-title {
    font-size: 24px !important;
    margin-top: 16px !important;
  }
  .format-options {
    width: 215px !important;
  }
  .select-dropdown {
    width: 48% !important;
    flex-direction: row;
    height: 40px !important;
  }
  .select-row {
    gap: 0 !important;
    width: 51% !important;
  }
  .format-row {
    width: 24% !important;
    margin-right: 16px !important;
  }
  .OnTimePerformance {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .table-container1 {
    height: calc(100vh - 249px);
  }
  .select-dropdown-format {
    width: 100% !important;
  }
  .button-row {
    width: 23%;
    margin-right: 16px;
  }
  .display-report-btn {
    width: 100% !important;
  }
} */
/* @media (max-width: 767px) {
  .header-1 {
    flex-direction: row;
    padding: 0px !important;
  }
  .Select-box {
    margin-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .format-options {
    width: 155px !important;
  }
  .ontime-title {
    font-size: 24px !important;
    margin-top: 0px !important;
  }
  .OnTimePerformance {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .export-btn {
    width: 100% !important;
    margin-bottom: 8px !important;
    color: black !important;
  }
  .table-container {
    width: 50%;
  }

  .content-ontime {
    padding: 16px !important;
    margin-bottom: 16px;
  }

  .date-pickers {
    flex-direction: row;
    padding-left: 0 !important;
    width: 52% !important;
  }

  .Date-picker {
    width: 100% !important;
    margin-bottom: 16px !important;
  }

  .Select-box {
    flex-direction: column;
    padding-left: 0 !important;
  }
  .select-row {
    width: 100% !important;
    gap: 1rem;
  }
  .report-criteria {
    padding: 0px !important;
    margin-bottom: 0px !important;
  }
  .report-criteria-text {
    padding: 0px !important;
  }

  .format-row,
  .button-row {
    flex-direction: column;
    width: 100% !important;
  }
  .format-row {
    margin-bottom: 16px;
  }
  .select-dropdown {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 16px !important;
  }

  .display-report-btn {
    width: 100% !important;
    margin-bottom: 4px !important;
  }

  .select-dropdown-format {
    width: 100% !important;
  }
  .no-data-border {
    width: 80% !important;
  }
  .table-section {
    height: calc(100vh - 250px);
  }
} */

/* Reference Group */

.sub-header {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
}
.add-new-btn {
  width: 104px;
  height: 30px;
  gap: 0px;
  border-radius: 8px !important;
  font-family: Roboto-medium !important;
}

.icon_bcg {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.spanicon {
  width: 40px;
  height: 40px;
  background-color: transparent !important;
  border: 0 !important;
}

.spanicon img {
  width: 24px;
  height: 24px;
}

.spanicon:hover {
  border-radius: 100px;
  background: rgba(0, 0, 0, 0.04) !important;
}

.Edit-Icon {
  color: rgba(0, 0, 0, 0.56);
  margin-right: 1rem;
  margin-left: 16px;
}
.Delete-Icon {
  color: rgba(0, 0, 0, 0.56);
}

.icon_bcg:hover {
  border-radius: 100px;
  background: var(--action-hover, rgba(0, 0, 0, 0.04));
  /* padding: 5px; */
}

.reference-no-data-field {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 8px 16px 16px;
}
.Table-row {
  padding: 16px !important;
}
.table-cell-name {
  width: 200px;
}
.table-cell-icon {
  width: 120px !important;
}

.sort-icon {
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.56);
  display: flex;
  align-items: center;
  justify-content: center;
}

.refer-value {
  display: flex;
  align-items: center;
}

.small-icon .MuiSvgIcon-root {
  width: 18px;
}

/* 
.sort-icon.asc::before {
  content: "▲";
  color: grey;
} */

/* .sort-icon.desc::before {
  content: "▼";
  color: grey;
} */
.table-container-reference {
  /* overflow-y: auto; */
  box-shadow: none !important;
  height: auto;
}
.import_no_data_field_reference {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 8px 16px 16px;
  width: 60%;
}
.text-fields {
  width: 489px !important;
}

.plr-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.refer-group {
  background-color: "rgba(0, 0, 0, 0.04)";
}

.plr-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.route-selected {
  border: 1px solid #046386;
  background: var(--primary-selected, #04638614);
}

.route-headlbl2 {
  font-size: 16px;
  font-family: Roboto-medium;
  color: #046386;
  line-height: 24px;
  margin-bottom: 4px;
}

.ground-route {
  margin-right: 7px;
}
.Blue-main-head {
  font-size: 16px;
  font-family: Roboto-medium !important;
  line-height: 24px;
  color: #046386;
  padding-bottom: 4px;
}

.Filter-section {
  display: flex;
  flex-direction: row !important;
  gap: 16px;
  justify-content: end;
}

.active_search_btn {
  width: 180px;
  height: 40px;
  border-radius: 8px !important;
  font-size: 15px !important;
}

.reset_btn_filter {
  width: 260px;
  height: 40px;
  border-radius: 8px !important;
  font-size: 15px !important;
  font-family: Roboto-medium !important;
  font-size: 15px;
  padding: 5px 5px !important;
}

.hide-sort-arrow .MuiTableSortLabel-icon {
  display: none;
}

.active-order .paddingTop-active-filters {
  padding-top: 14px !important;
}

.hcAutoComplete .MuiAutocomplete-inputRoot {
  padding-right: 15px !important;
}
.hcAutoComplete .MuiAutocomplete-clearIndicator {
  display: none !important ;
}

.dropDownIcon {
  color: #0000008f;
}

.dropDownIcon.rotate {
  transform: rotate(180deg);
}
.width-94 {
  width: 94px;
  margin-right: 64px;
}

.detail-info {
  font-size: 14px;
  font-family: Roboto-medium !important;
}
.rateEstimateText {
  justify-content: flex-end;
  display: flex;
  margin-right: 8px !important ;
  padding-bottom: 4px !important ;
}
.MuiTooltip-popper[data-popper-placement*="bottom"]
  .css-ja5taz-MuiTooltip-tooltip {
  margin-top: 1px !important; /* Change this value to the desired margin */
}

.widthAuto {
  width: auto;
}

.width70 {
  width: 70px;
}

.width80 {
  width: 80px;
}

.release-popup .MuiPaper-elevation {
  width: 100% !important;
}

.accordion-section {
  box-shadow: none !important;
  border: 1px solid var(--components-input-outlined-enabledBorder, #0000003b);
  border-radius: 4px !important;
}

.accordion-section::before {
  background-color: transparent !important;
}

.release-notes-container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  color: var(--components-alert-info-color, #00677e);
  font-feature-settings: "liga" off, "clig" off;

  /* alert/title */
  font-family: Roboto-medium;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.accordion-info {
  width: 100%;
}

.accordion-info section {
  width: 100%;
  overflow: hidden !important;
}

.release-notes-text {
  margin: 0;
  flex-grow: 1; /* Ensures it takes available space */
}

.view-release-notes {
  color: var(--primary-main, #046386);
  font-family: Roboto-medium;
  font-size: 15px;
  font-style: normal;
  line-height: 26px; /* 173.333% */
  letter-spacing: 0.46px;
  text-transform: uppercase;
  cursor: pointer;
}

.alert-msg .MuiAlert-message {
  width: 100%;
  padding: 5px 0;
}

.mtb4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
